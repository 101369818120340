import { useContext } from 'react'
import imageCancel from '../../assets/cancel.png'
import { AppContext, AppContextInterface } from '../../context/AppContext'
import useSearchFilters from '../../hooks/useSearchFilters'
import Button from '../Button/Button'
import styles from './AppliedFilters.module.scss'

export default function AppliedFilters() {
  const context: AppContextInterface = useContext(AppContext)
  const { filters, setFilters, clearFilters } = useSearchFilters()

  const matchMe = filters.matchMe
  const industry = filters.industry
  const jobType = context.state.jobTypes.find(it => it.id === filters.jobType)
  const posted = context.state.postedOptions.find(it => it.value == filters.posted)
  const experienceLevels = filters.experience
    ?.map(expId => context.state.experienceOptions.find(opt => opt.value === expId))
    .filter(it => it)

  return (
    <div className={styles.filters}>
      {matchMe && (
        <div className={styles.selectedFilters}>
          <p>Match my resume</p>
          <img src={imageCancel} alt='cancel' onClick={() => setFilters({ ...filters, matchMe: false })} />
        </div>
      )}
      {industry && (
        <div className={styles.selectedFilters}>
          <p>{industry}</p>
          <img src={imageCancel} alt='cancel' onClick={() => setFilters({ ...filters, industry: undefined })} />
        </div>
      )}
      {jobType && (
        <div className={styles.selectedFilters}>
          <p>{jobType?.value}</p>
          <img src={imageCancel} alt='cancel' onClick={() => setFilters({ ...filters, jobType: undefined })} />
        </div>
      )}
      {filters.pay && (
        <div className={styles.selectedFilters}>
          <p>
            ${filters.pay[0]?.toLocaleString('en-US')} - ${filters.pay[1]?.toLocaleString('en-US')}
          </p>
          <img src={imageCancel} alt='cancel' onClick={() => setFilters({ ...filters, pay: [40000, 200000] })} />
        </div>
      )}
      {posted && (
        <div className={styles.selectedFilters}>
          <p>{posted?.label}</p>
          <img src={imageCancel} alt='cancel' onClick={() => setFilters({ ...filters, posted: undefined })} />
        </div>
      )}
      {experienceLevels &&
        experienceLevels.map((experience, id) => {
          return (
            <div key={id} className={styles.selectedFilters}>
              <p>{experience?.label}</p>
              <img
                src={imageCancel}
                alt='cancel'
                onClick={() =>
                  setFilters({ ...filters, experience: filters?.experience?.filter(it => it != experience?.value) })
                }
              />
            </div>
          )
        })}

      <div className={styles.clearEmpty}></div>
      <Button text={'Clear All'} link={true} onClick={() => clearFilters()} />
    </div>
  )
}
