import React, { useState, useRef, useEffect, MouseEvent } from 'react'
import { Link } from 'react-router-dom'
import Icon from '../Icon/Icon'
import styles from './ProfileMenuEmployer.module.scss'
import { useLogoutMutation } from '../../hooks/useLogoutMutation'
import ErrorMessage from '../ErrorMessage/ErrorMessage'
import { NavLink } from 'react-router-dom'
import { useCurrentCompany } from '../../hooks/useCompany'

export default function ProfileMenuEmployer() {
  const { isPending, isError, data: company, error } = useCurrentCompany()

  const logoutMutation = useLogoutMutation()
  const [isOpen, setIsOpen] = useState(false)
  const menuRef = useRef<HTMLDivElement>(null)

  const toggleMenu = () => {
    setIsOpen(!isOpen)
  }

  const closeMenu = () => {
    setIsOpen(false)
  }

  const handleClickOutside = (event: MouseEvent<Document>) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      closeMenu()
    }
  }
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside as any)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside as any)
    }
  }, [])

  // const { isPending, isError, data: user, error } = useCurrentUser()
  if (isPending) return <span>Loading...</span>
  if (isError) return <ErrorMessage error={error} />

  return (
    <div className={styles.menu_container} ref={menuRef}>
      <div className={styles.account} onClick={toggleMenu}>
        <Icon iconName={'profileFillBlack'} color={'black'} size={30} />
        <Icon iconName={'arrowDown'} color={'grey'} size={12} />
      </div>
      {isOpen && (
        <ul className={styles.menu}>
          <div className={styles.profile}>
            <div className={styles.picture}>
              <img src={company.logo || 'Logo'} alt='profile picture' />
            </div>

            <div className={styles.right}>
              <p>{company.name}</p>
              <Link className={styles.link} to={`/emp/company-profile`}>
                <li>View Company</li>
              </Link>
            </div>
          </div>
          <div className={styles.parent_items}>
            <li className={styles.it1}>
              <NavLink className={({ isActive }) => (isActive ? styles.selected : styles.item)} to='/emp/post-job'>
                <Icon iconName={'bluePaperPlane'} color={'black'} size={15} />
                <p>Post A New Job</p>
              </NavLink>
            </li>
            <li className={styles.it1}>
              <NavLink className={({ isActive }) => (isActive ? styles.selected : styles.item)} to='/emp/manage-jobs'>
                <Icon iconName={'bag'} color={'black'} size={15} />
                <p>Manage Jobs</p>
              </NavLink>
            </li>
            <li className={styles.it1}>
              <NavLink
                className={({ isActive }) => (isActive ? styles.selected : styles.item)}
                to='/emp/all-applicants'
              >
                <Icon iconName={'papers'} color={'black'} size={15} />
                <p>All Applicants</p>
              </NavLink>
            </li>
            <li className={styles.it1}>
              <NavLink className={({ isActive }) => (isActive ? styles.selected : styles.item)} to='/emp/contact-us'>
                <Icon iconName={'envelope'} color={'black'} size={15} />
                <p>Contact Us</p>
              </NavLink>
            </li>
            <li onClick={() => logoutMutation.mutate()} className={styles.it1}>
              <Icon iconName={'door_out'} color={'black'} size={15} />
              <p>Sign Out</p>
            </li>
          </div>
        </ul>
      )}
    </div>
  )
}
