import React from "react";

export default function useOutsideClick(callback:()=>void){
    const ref = React.useRef<HTMLDivElement>(null);
  
    React.useEffect(() => {
      const handleClick = (event:MouseEvent) => {
        if (ref.current && !ref.current.contains(event.target as Node)) {
          callback();
        }
      };
  
      document.addEventListener('click', handleClick, true);
  
      return () => {
        document.removeEventListener('click', handleClick, true);
      };
    }, [ref]);
  
    return ref;
};