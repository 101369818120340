import { z } from 'zod'

export enum UserType {
  EMPLOYER = 'EMPLOYER',
  CANDIDATE = 'CANDIDATE',
}

export const loginSchema = z.object({
  email: z.string().min(1, 'Username/email is required'),
  password: z.string().min(5, 'Password must be at least 8 characters long'),
  rememberMe: z.boolean(),
})
export type LoginRequest = z.infer<typeof loginSchema>
export type LoginRequestErrors = Partial<Record<keyof LoginRequest, string>>

export const registerSchema = z
  .object({
    userType: z.nativeEnum(UserType),
    email: z.string().min(1, 'Email is required').email(),
    password: z.string().min(8, 'Password should be at lest 8 characters'),
    confirmPassword: z.string().min(8, 'Please enter at lest 8 characters'),
    firstName: z.string().optional(),
    lastName: z.string().optional(),
    location: z.string().optional(),
    phone: z.string().optional(),
  })
  .superRefine(({ confirmPassword, password }, ctx) => {
    if (confirmPassword !== password) {
      ctx.addIssue({
        code: 'custom',
        path: ['confirmPassword'],
        message: 'The passwords did not match',
      })
    }
  })
export type RegisterRequest = z.infer<typeof registerSchema>
export type RegisterRequestErrors = Partial<Record<keyof RegisterRequest, string>>

// TODO:
export const CertificateSchema = z.object({
  id: z.number(),
  name: z.string().nullable().optional(),
  avatar: z.string().optional(),
  url: z.string().nullable().optional(),
})
export type Certificate = z.infer<typeof CertificateSchema>

export const ExperienceSchema = z.object({
  id: z.number(),
  userId: z.number(),
  startDate: z.string().optional().nullable(),
  endDate: z.string().optional().nullable(),
  position: z.string().optional(),
  company: z.string().optional(),
  description: z.string().optional(),
})
export const ExperienceCreateSchema = ExperienceSchema.extend({
  id: z.number().optional(), // Override
})
export type Experience = z.infer<typeof ExperienceSchema>
export type ExperienceCreate = z.infer<typeof ExperienceCreateSchema>

export const EducationSchema = z.object({
  id: z.number(),
  userId: z.number(),
  startDate: z.string().optional(),
  endDate: z.string().optional(),
  degree: z.string().optional(),
  institution: z.string().optional(),
  description: z.string().optional(),
})
export const EducationCreateSchema = EducationSchema.extend({
  id: z.number().optional(), // Override
})

export type Education = z.infer<typeof EducationSchema>
export type EducationCreate = z.infer<typeof EducationCreateSchema>

export const JobSchema = z.object({
  id: z.number(),
  title: z.string().optional(),
  industry: z.string().optional(),
  url: z.string().optional(),
  location: z.string().optional(),
  payFrom: z.number().optional(),
  payTo: z.number().optional(),
  jobType: z.string().min(1, { message: 'Please select a job type' }),
  deadline: z.string().optional().nullable(),
  description: z.string().optional(),
  responsibilities: z.string().optional(),
  requirements: z.string().optional(),
  experience: z.number().optional(),
  qualification: z.string().optional(),
  careerLevel: z.string().optional(),
  creatorId: z.number().optional(),
  companyId: z.number().optional(),
  nrVacancies: z.number().optional(),
  createdAt: z.string().optional(),
})

export const JobCreateSchema = JobSchema.extend({
  id: z.number().optional(), // Override
})

export type Job = z.infer<typeof JobSchema>
export type JobCreate = z.infer<typeof JobCreateSchema>

const urlWithoutProtocol = z.string().refine(
  value => {
    // Regular expression to match URL without protocol
    // const urlPattern = /^(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/\S*)?$/
    const urlPattern = /^www\.[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\/\S*)?$/
    return urlPattern.test(value)
  },
  {
    message: 'Please enter a valid website address',
  },
)
const facebookUrlSchema = z
  .string()
  .regex(/^https?:\/\/www\.facebook\.com\//, "URL must start with 'https://www.facebook.com/'")
  .optional()
  .or(z.literal(''))

const twitterUrlSchema = z
  .string()
  .regex(/^https?:\/\/www\.x\.com\//, "URL must start with 'https://www.x.com/'")
  .optional()
  .or(z.literal(''))

const linkedinUrlSchema = z
  .string()
  .regex(/^https?:\/\/www\.linkedin\.com\//, "URL must start with 'https://www.linkedin.com/'")
  .optional()
  .or(z.literal(''))
export const CompanySchema = z.object({
  id: z.number(),
  name: z.string().min(1, { message: 'Please enter your company name' }),
  email: z.string().email('Enter a valid email').optional().nullable(),
  phone: z.string().optional(),
  website: urlWithoutProtocol.optional(),
  logo: z.string().optional(),
  about: z.string({ required_error: 'Please enter a concise information about your company' }).optional(),
  employees: z.number().optional(),
  jobs: z.array(JobSchema).optional(),
  facebook: facebookUrlSchema,
  twitter: twitterUrlSchema,
  linkedin: linkedinUrlSchema,
  location: z.string({ required_error: 'Please enter your company business address' }).optional(),
  founded: z.string({ required_error: 'Please enter a date' }).optional().nullable(),
})

export type Company = z.infer<typeof CompanySchema>

export interface JobWithCompany extends Job {
  company?: Company
}

export const UserSchema = z.object({
  id: z.number(),
  firstName: z.string().optional(),
  lastName: z.string().optional(),
  about: z.string().optional(),
  email: z.string().optional(),
  phone: z.string().optional(),
  title: z.string().optional(),
  avatar: z.string().optional(),
  isEmployer: z.boolean().optional(),
  role: z.string().optional(),
  location: z.string().optional(),
  city: z.string().optional(), // ??
  position: z.string().optional(),
  resume_link: z.string().optional(),
  preferencesTitles: z.array(z.string()).optional(),
  preferencesWorkplaces: z.array(z.string()).optional(),
  preferencesLocations: z.array(z.string()).optional(),
  preferencesTypes: z.array(z.string()).optional(),
  skills: z.array(z.string()).optional(),
  companyId: z.number().optional(),
  company: CompanySchema.optional(),
  // experiences: z.array(Experience).optional(),
  // educations: z.array(Education).optional(),
  // certificates:
  // saved_certificates
  // applied_jobs
  // saved_jobs
})

export type User = z.infer<typeof UserSchema>

export interface Profile extends User {
  experiences?: Experience[]
  educations?: Education[]
}

export interface LoginRegisterResponse {
  accessToken: string
  refreshToken: string
}

export enum CandidateStatus {
  New = 'NEW',
  Rejected = 'REJECTED',
  Accepted = 'ACCEPTED',
  Interview = 'INTERVIEW',
  Hired = 'HIRED',
}

export const RelatedExperience = z.object({
  position: z.string().optional(),
  company: z.string().optional(),
})

export const JobApplicationSchema = z.object({
  id: z.number(),
  status: z.nativeEnum(CandidateStatus),
  email: z.string().optional(),
  resume: z.string().optional(),
  jobId: z.number(),
  candidateId: z.number(),
  createdAt: z.string().optional(),
  relatedExperiences: z.array(RelatedExperience).optional(),
})
export const JobApplicationCreateSchema = JobApplicationSchema.extend({
  id: z.number().optional(), // Override
})

export type JobApplication = z.infer<typeof JobApplicationSchema>
export type JobApplicationCreate = z.infer<typeof JobApplicationCreateSchema>

export interface JobApplicationWithCandidate extends JobApplication {
  candidate?: User
}
export interface JobWithApplicationsAndUsers extends Job {
  applications: JobApplicationWithCandidate[]
}

export interface JobApplicationWithJob extends JobApplication {
  job?: JobWithCompany
}

export interface ContactUs {
  name: string
  email: string
  message: string
}
