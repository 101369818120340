import React from 'react'
import styles from './CheckboxGroup.module.scss'

interface Option {
  value: string
  label: string
}

interface Props {
  options: Option[]
  value: string[]
  onChange: (value: string[]) => void
}

const CheckboxGroup = ({ options, onChange, value }: Props) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value
    const index = value.indexOf(newValue)
    const newSelectedValues = [...value]

    if (index === -1) {
      newSelectedValues.push(newValue)
    } else {
      newSelectedValues.splice(index, 1)
    }
    onChange(newSelectedValues)
  }

  return (
    <div className={styles.checkboxGroup}>
      {options.map(option => (
        <div key={option.value} className={styles.checkboxContainer}>
          <label htmlFor={option.value}>
            <input
              type='checkbox'
              name='checkboxButton'
              value={option.value}
              checked={value?.includes(option.value)}
              onChange={handleChange}
              id={option.value}
            />
            <span className={styles.checkmark}></span>
            {option.label}
          </label>
        </div>
      ))}
    </div>
  )
}

export default CheckboxGroup
