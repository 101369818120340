import { useMutation, useQueryClient } from '@tanstack/react-query'
import Dialog from 'rc-dialog'
import { useEffect, useState } from 'react'
import { createExperience, updateExperience } from '../../api/api'
import Button from '../../components/Button/Button'
import Input from '../../components/Input/Input'
import { useUser } from '../../hooks/useUser'
import { Experience, ExperienceSchema } from '../../schemas/schemas'
// import styles from './ProfilePage.module.scss'
import axios from 'axios'
import { DateTime } from 'luxon'
import DatePicker from 'react-date-picker'
import { z } from 'zod'
import Icon from '../../components/Icon/Icon'
import Spinner from '../../components/Spinner/Spinner'
import { useDeleteExperienceMutation } from '../../hooks/useDeleteExperienceMutation'
import { formatDateRange } from '../../utils/date'
import styles from './ManageExperiences.module.scss'
import TextArea from '../../components/TextArea/TextArea'

interface Props {
  userId: number
  onClose: () => void
}

// Override
export const ExperienceEditSchema = ExperienceSchema.extend({
  id: z.number(),
  userId: z.number(),
  startDate: z.string().min(1, { message: 'Please enter startDate' }),
  endDate: z.string().nullable(),
  position: z.string().min(1, { message: 'Please enter position' }),
  company: z.string().min(1, { message: 'Please enter company' }),
  description: z.string().min(1, { message: 'Please enter description' }),
})
export const ExperienceCreateSchema = ExperienceEditSchema.extend({
  id: z.number().optional(), // Override
})
export type ExperienceEdit = z.infer<typeof ExperienceEditSchema>
export type ExperienceCreate = z.infer<typeof ExperienceCreateSchema>

const Errors = ({ data }: { data?: string[] }) => {
  if (!data || data.length === 0) return null
  if (data.length == 1) return <div className={styles.red}>{data?.[0]}</div>
  return (
    <div className={styles.red}>
      <ul>
        {data.map((errorMessage, index) => (
          <li key={index}>{errorMessage}</li>
        ))}
      </ul>
    </div>
  )
}

export default function ManageExperiences({ onClose, userId }: Props) {
  const { data: user } = useUser(userId)
  const [editExperienceId, setEditExperienceId] = useState<number | null>(null)
  const [addExperience, setAddExperience] = useState(false)
  const [errors, setErrors] = useState<Partial<Record<keyof Experience, string[]>>>({})
  const queryClient = useQueryClient()
  const deleteExperienceMutation = useDeleteExperienceMutation(userId)

  const [startDate, setStartDate] = useState<Date>()
  const [endDate, setEndDate] = useState<Date>()
  const [company, setCompany] = useState<string>('')
  const [position, setPosition] = useState<string>('')
  const [description, setDescription] = useState<string>('')

  const updateExperienceMutation = useMutation({
    mutationFn: updateExperience,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['user', userId] })
    },
  })
  const createExperienceMutation = useMutation({
    mutationFn: createExperience,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['user', userId] })
    },
  })

  useEffect(() => {
    if (editExperienceId) {
      const editExperience = user?.experiences?.find(it => it.id === editExperienceId)
      if (editExperience) {
        setStartDate(editExperience.startDate ? DateTime.fromISO(editExperience.startDate).toJSDate() : undefined)
        setEndDate(editExperience.endDate ? DateTime.fromISO(editExperience.endDate).toJSDate() : undefined)
        setCompany(editExperience.company || '')
        setPosition(editExperience.position || '')
        setDescription(editExperience.description || '')
      }
    }
  }, [editExperienceId])

  useEffect(() => {
    if (addExperience) {
      setStartDate(undefined)
      setEndDate(undefined)
      setCompany('')
      setPosition('')
      setDescription('')
    }
  }, [addExperience])

  const handleEditExperience = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    if (!editExperienceId) {
      setErrors({ description: ['Missing experience id'] })
      return
    }

    const payload: ExperienceEdit = {
      id: editExperienceId,
      userId,
      company,
      position,
      description,
      startDate: startDate ? DateTime.fromJSDate(startDate).toISODate() || '' : '',
      endDate: startDate ? DateTime.fromJSDate(startDate).toISODate() : null,
    }

    try {
      ExperienceEditSchema.parse(payload)
      await updateExperienceMutation.mutateAsync(payload)
      setEditExperienceId(null)
      setErrors({})
    } catch (error) {
      if (error instanceof z.ZodError) {
        setErrors(error.flatten().fieldErrors)
      } else if (axios.isAxiosError(error)) {
        // setErrors({  })
      } else {
        // setErrors({ })
      }
    }
  }

  const handleAddEditExperience = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    const payload: ExperienceCreate = {
      userId,
      company,
      position,
      description,
      startDate: startDate ? DateTime.fromJSDate(startDate).toISODate() || '' : '',
      endDate: startDate ? DateTime.fromJSDate(startDate).toISODate() : null,
    }

    try {
      ExperienceCreateSchema.parse(payload)
      await createExperienceMutation.mutateAsync(payload)
      setAddExperience(false)
      setErrors({})
    } catch (error) {
      if (error instanceof z.ZodError) {
        setErrors(error.flatten().fieldErrors)
      } else if (axios.isAxiosError(error)) {
        // setErrors({  })
      } else {
        // setErrors({ })
      }
    }
  }

  const handleDeleteExperience = async (expId: number) => {
    if (window.confirm('Are you sure you want to delete this experience?')) {
      await deleteExperienceMutation.mutateAsync(expId)
    }
  }

  return (
    <>
      {updateExperienceMutation.isPending && <Spinner />}

      {/* List modal */}
      <Dialog
        title='Available experiences for edit'
        onClose={onClose}
        className={styles.dialogExperiencesModal}
        visible={true}
        maskAnimation='fade'
        style={{ top: '30%' }}
      >
        {user?.experiences?.map(exp => (
          <tr key={exp.id} className={styles.item}>
            <td>{formatDateRange(exp.startDate, exp.endDate)}</td>
            <td>{exp.company}</td>
            <td>{exp.position}</td>
            <td>
              <button
                onClick={() => setEditExperienceId(exp.id)}
                className={`${styles.actionButton} ${styles.editButton}`}
              >
                <Icon iconName={'pencilEditOrange'} />
              </button>
              <button onClick={() => handleDeleteExperience(exp.id)} className={styles.actionButton}>
                {' '}
                <Icon iconName={'trash'} />{' '}
              </button>
            </td>
          </tr>
        ))}
        <div className={styles.footer}>
          <Button primary medium text='Add' onClick={() => setAddExperience(true)} />
        </div>
      </Dialog>

      {/* Add / edit modal  */}
      <Dialog
        title={addExperience ? 'Add experience' : 'Edit experience'}
        onClose={() => (addExperience ? setAddExperience(false) : setEditExperienceId(null))}
        visible={addExperience || editExperienceId != null}
        className={styles.shortWidth}
        maskAnimation='fade'
        style={{ top: '20%' }}
      >
        <form
          onSubmit={editExperienceId ? handleEditExperience : handleAddEditExperience}
          className={styles.formLayout}
        >
          <div>
            <label>Start date</label>
            <DatePicker onChange={d => setStartDate(d as Date)} value={startDate} maxDate={new Date()} />
            <Errors data={errors?.startDate} />
          </div>
          <div>
            <label>End date</label>
            <DatePicker onChange={d => setEndDate(d as Date)} value={endDate} />
            <Errors data={errors?.endDate} />
          </div>
          <div>
            <label>Company</label>
            <Input value={company} onChange={e => setCompany(e.target.value)} />
            <Errors data={errors?.company} />
          </div>

          <div>
            <label>Position</label>
            <Input value={position} onChange={e => setPosition(e.target.value)} />
            <Errors data={errors?.position} />
          </div>

          <div>
            <label>Description</label>
            <TextArea value={description} onChange={e => setDescription(e.target.value)} />
            <Errors data={errors?.description} />
          </div>

          <div className={styles.footer}>
            <Button primary medium text='Save' />
          </div>
        </form>
      </Dialog>
    </>
  )
}
