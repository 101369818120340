import { useQuery } from '@tanstack/react-query'
import CandidatesList from '../../components/CandidatesList/CandidatesList'
import Icon from '../../components/Icon/Icon'
import styles from './EmployerAllApplicants.module.scss'
import { useAuth } from '../../context/AuthContext'
import { fetchJobsApplicationsAndUsers } from '../../api/api'
import { CandidateStatus } from '../../schemas/schemas'
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage'
import { useCurrentUser } from '../../hooks/useCurrentUser'

export const CandidateStatusText = {
  [CandidateStatus.New]: 'New/Pending',
  [CandidateStatus.Rejected]: 'Rejected',
  [CandidateStatus.Accepted]: 'Accepted',
  [CandidateStatus.Interview]: 'Invited to Interview',
  [CandidateStatus.Hired]: 'Hired',
}

export default function EmployerAllApplicants() {
  const { isAuthenticated } = useAuth()
  const { data: user } = useCurrentUser()
  const { data, isLoading, isError, error } = useQuery({
    queryKey: ['jobsApplicationsUsers'],
    queryFn: () => fetchJobsApplicationsAndUsers(user?.id || 0),
    // staleTime: 1000 * 60 * 60, // 60 minutes
    enabled: isAuthenticated && !!user,
  })

  const totalApplications = data?.reduce((total, job) => total + job.applications.length, 0)

  if (isLoading) return <div>Loading...</div>
  if (isError) return <ErrorMessage error={error} />

  return (
    <div>
      <h1 className={styles.title}>All Applicants</h1>
      <div className={styles.manage}>
        <p className={styles.top}>Manage Jobs Applications</p>
        <div className={styles.main}>
          <div className={styles.green}>
            <Icon iconName='greenFile' size={48} />
          </div>
          <div className={styles.total}>
            <p className={styles.text}>Total Applications</p>
            <p className={styles.number}>{totalApplications}</p>
          </div>
        </div>
      </div>
      {data
        ?.filter(it => it.applications?.length)
        .map(job => (
          <CandidatesList key={job.id} title={job.title || ''} applications={job.applications} />
        ))}
    </div>
  )
}
