import Icon from '../../components/Icon/Icon'
import styles from './MessagesPage.module.scss'

export default function MessagesPage() {
  return (
    <div className={styles.main}>
      <div className={styles.left}>
        <div className={styles.search}>
          <div className={styles.search_left}>
            <Icon iconName={'search'} color={'#707C97'} size={18} />
            <b>Search </b>
          </div>
          <div className={styles.search_filter}>
            <p>Recent Chats</p>
            <Icon iconName={'arrowDownGray'} color={'#707C97'} size={12} />
          </div>
        </div>
        {/* Left - Message cards */}
        <div className={styles.messageCardsCont}>
          {/* Left - Message card1 */}
          <div className={styles.messageCard1}>
            <div className={styles.top}>
              <div className={styles.left}>
                <div className={styles.logoNotif}>
                  <div className={styles.greenCircle}></div>
                  {/* <img className={styles.logo} src={context.state.jobs[0].companyLogo} alt='companyLogo' /> */}
                </div>

                <div className={styles.description}>
                  <div className={styles.name}>Luy Robin</div>
                  <div className={styles.status}>... writes</div>
                </div>
                <div className={styles.timeAgo}>1 minute ago</div>
              </div>
            </div>
            <div className={styles.bottom}>
              <div className={styles.content}>
                Most of its text is made up from sections 1.10.32–3 of Cicero's De finibus
              </div>
              <div className={styles.newMessages}>2</div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.right}></div>
    </div>
  )
}
