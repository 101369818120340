import { useState } from 'react'
import imageCancel from '../../assets/cancel.png'
import { JobApply } from '../../pages/JobDetailsPage/JobDetailsPage'
import { ExperienceCreate } from '../../schemas/schemas'
import Input, { Color } from '../Input/Input'
import styles from './RelevantJobExperience.module.scss'

interface Props {
  jobApply: JobApply
  setJobApply: React.Dispatch<React.SetStateAction<JobApply>>
}

export default function RelevantJobExperience({ jobApply, setJobApply }: Props) {
  const [jobTitle, setJobTitle] = useState('')
  const [companyName, setCompanyName] = useState('')

  const jobList = jobApply.jobItemsList

  const removeJob = (id: number) => {
    const newJobList = jobList.filter((_, index) => index != id)
    setJobApply(prev => ({ ...prev, jobItemsList: newJobList }))
  }

  const addJobItem = (newJobTitle: string, newCompanyName: string) => {
    const newJob: ExperienceCreate = { position: newJobTitle, company: newCompanyName, userId: 0 }
    const newJobList = [...jobList, newJob]
    setJobApply(prev => ({ ...prev, jobItemsList: newJobList }))
  }

  const handleAddJob = (e: React.FormEvent) => {
    e.preventDefault()
    addJobItem(jobTitle, companyName)
    setJobTitle('')
    setCompanyName('')
  }

  return (
    <div>
      <form action='' onSubmit={handleAddJob}>
        <div className={styles.contactDetails}>
          <p>Job title</p>
          <Input bgColor={Color.White} value={jobTitle} onChange={e => setJobTitle(e.target.value)} />
        </div>
        <div className={styles.contactDetails}>
          <p>Company</p>
          <Input bgColor={Color.White} value={companyName} onChange={e => setCompanyName(e.target.value)} />
        </div>
        <div className={styles.add}>
          <button className={styles.blackButton} disabled={!jobTitle || !companyName}>
            Add Job/Experience
          </button>
        </div>
      </form>

      {jobList.map((job, idx) => (
        <div className={styles.card} key={job.id}>
          <div>
            Company: <b>{job.company}</b>
          </div>
          <div>
            Job Title: <b>{job.position}</b>
          </div>
          <button onClick={() => removeJob(idx)} className={styles.cancelButton}>
            <img src={imageCancel} alt='Remove job' />
          </button>
        </div>
      ))}
    </div>
  )
}
