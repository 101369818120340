import React, { StrictMode } from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import './fonts/Gordita/stylesheet.css'
import './fonts/Gotham/stylesheet.css'
import App from './App'

const root = ReactDOM.createRoot(document.getElementById('root') as Element)
root.render(
  <StrictMode>
    <meta name='viewport' content='width=device-width, initial-scale=1.0' />
    <meta charSet='utf-8' />
    <link rel='preconnect' href='https://fonts.googleapis.com' />
    <link rel='preconnect' href='https://fonts.gstatic.com' />
    <link href='https://fonts.googleapis.com/css2?family=Didact+Gothic&display=swap' rel='stylesheet' />
    <link
      rel='stylesheet'
      href='https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200'
    />
    <link
      rel='stylesheet'
      href='https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0'
    />
    <link rel='stylesheet' href='https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.1/css/all.min.css' />
    <App />
  </StrictMode>,
)
