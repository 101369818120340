export function getRememberMe(): boolean {
  return localStorage.getItem('rememberMe') ? true : false
}

export function setRememberMe(rememberMe: boolean) {
  if (rememberMe) localStorage.setItem('rememberMe', 'y')
  else localStorage.removeItem('rememberMe')
}

export function getAccessToken(): string | null {
  return sessionStorage.getItem('accessToken') || localStorage.getItem('accessToken')
}

export function getRefreshToken(): string | null {
  return sessionStorage.getItem('refreshToken') || localStorage.getItem('refreshToken')
}

export function setAccessToken(token: string) {
  if (getRememberMe()) localStorage.setItem('accessToken', token)
  else sessionStorage.setItem('accessToken', token)
}

export function setRefreshToken(token: string) {
  if (getRememberMe()) localStorage.setItem('refreshToken', token)
  else sessionStorage.setItem('refreshToken', token)
}

export function clearTokens() {
  localStorage.removeItem('accessToken')
  localStorage.removeItem('refreshToken')
  sessionStorage.removeItem('accessToken')
  sessionStorage.removeItem('refreshToken')
}
