import styles from './HomePage.module.scss'
import { useNavigate } from 'react-router-dom'
import SearchForm from '../../components/SearchForm/SearchForm'

export default function Home() {
  const navigate = useNavigate()

  return (
    <div className={styles.home}>
      <div className={styles.action}>
        {/* <h1 className={styles.ad}>
          There are <span className={styles.orange}>75,351</span> Postings Here <br />
          For you!
        </h1> */}
        <h1 className={styles.ad}> Start Your Job Search Here! </h1>
        <h3 className={styles.font}>Find Jobs, Employment & Career Opportunities</h3>
        <div className={styles.searchContainer}>
          <SearchForm
            onSearch={(title, location) => navigate(`/search?title=${title}&location=${location}`)}
            hideLocation
          />
        </div>
        <h3 className={styles.font}>
          <b>Popular Searches</b> : Designer, Developer, Web, iOS, PHP, Senior Engineer
        </h3>
      </div>
      <div className={styles.person}></div>
    </div>
  )
}
