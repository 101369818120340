import { useQuery } from '@tanstack/react-query'
import { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { getJobWithCompany } from '../../api/api'
import DetailsHeader from '../../components/DetailsHeader/DetailsHeader'
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage'
import facebookImgBt from '../../components/Icon/svg icons/buttonFacebook.svg'
import linkedinImgBt from '../../components/Icon/svg icons/buttonLinkedin.svg'
import pinterestImgBt from '../../components/Icon/svg icons/buttonPinterest.svg'
import twitterImgBt from '../../components/Icon/svg icons/buttonTwitter.svg'
import IconWithText from '../../components/IconWithText/IconWithText'
import JobApplication from '../../components/JobApplication/JobApplication'
import JobCard from '../../components/JobCard/JobCard'
import { useCurrentUser } from '../../hooks/useCurrentUser'
import { useSimilarJobs } from '../../hooks/useSimilarJobs'
import { ExperienceCreate } from '../../schemas/schemas'
import { formatDate } from '../../utils/date'
import styles from './JobDetailsPage.module.scss'

export interface JobApply {
  step: number
  jobId: number
  file: File | null
  email: string
  firstName: string
  lastName: string
  location: string
  phone: string
  jobItemsList: ExperienceCreate[]
  candidateId?: number
}

export default function JobDetailsPage() {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const jobId = Number(useParams().jobId)
  const { data: user } = useCurrentUser()
  const initialized = useRef(false)
  const { data: filteredJobList } = useSimilarJobs(jobId, 2)

  // form
  const [jobApply, setJobApply] = useState<JobApply>({
    step: 0,
    jobId: jobId,
    firstName: '',
    lastName: '',
    location: '',
    email: '',
    phone: '',
    file: null,
    jobItemsList: [],
  })

  // Effect to update email once user data is fetched
  useEffect(() => {
    if (user && !initialized.current) {
      setJobApply(prev => ({
        ...prev,
        email: user.email || '',
        firstName: user.firstName || '',
        lastName: user.lastName || '',
        location: user.location || '',
        phone: user.phone || '',
      }))
      initialized.current = true // Mark as initialized
    }
  }, [user])

  // Query
  const {
    isPending,
    data: job,
    error,
  } = useQuery({
    queryKey: ['job', jobId],
    queryFn: () => getJobWithCompany(jobId),
    staleTime: 1000 * 60 * 10, // 10 minutes
  })
  if (isPending) return <span>Loading...</span>
  if (error) return <ErrorMessage error={error} />

  const applicationStep = jobApply.step

  if (!job) return <div>No job found</div>
  const posted = formatDate(job.createdAt)
  const experience = `${job.experience} ${job.experience === 1 ? 'year' : 'years'}`

  // const certificates: Certificate[] = []
  // job.certificates?.forEach(id => {
  //   const certificate = context.state.certificates.find(cert => cert.id == id)
  //   if (certificate) {
  //     certificates.push(certificate)
  //   }
  // })

  // Common header component for all steps
  const header = <DetailsHeader job={job} jobApply={jobApply} setJobApply={setJobApply} />

  if (applicationStep !== 4) {
    return (
      <div className={styles.main}>
        <div>
          {header}

          <div className={styles.main}>
            <div className={styles.center}>
              <div className={styles.paragraph}>
                <div className={styles.title}>Job Description</div>
                <div className={styles.text} dangerouslySetInnerHTML={{ __html: job.description || '' }} />
              </div>

              {job.responsibilities && (
                <div className={styles.paragraph}>
                  <div className={styles.title}>Key Responsibilities</div>
                  <div className={styles.text} dangerouslySetInnerHTML={{ __html: job.responsibilities }} />
                </div>
              )}

              {job.requirements && (
                <div className={styles.paragraph}>
                  <div className={styles.title}>Skill & Experience</div>
                  <div className={styles.text} dangerouslySetInnerHTML={{ __html: job.requirements || '' }} />
                </div>
              )}

              {/* <div className={styles.paragraph}>
                <div className={styles.title}>Recommended Certifications for this job</div>
                <Carousel items={certificates} />
              </div> */}
              <div className={styles.shareButtons}>
                <div className={styles.shareText}>Share this post </div>
                <a
                  href={`https://twitter.com/share?url=${window.location}&amp;text=${job.title}`}
                  title='Twitter'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <img src={twitterImgBt} alt='share on Twitter button' />
                </a>
                <a
                  href={`http://www.facebook.com/sharer.php?u=${window.location.href}`}
                  title='Facebook'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <img src={facebookImgBt} alt='share on Facebook button' />
                </a>
                <a
                  href={`http://pinterest.com/pin/create/button/?url=${window.location}&amp;description=${job.title}`}
                  title='Printerest'
                  rel='noopener noreferrer'
                >
                  <img src={pinterestImgBt} alt='share on Printerest button' />
                </a>
                <a
                  href={`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
                    window.location.href,
                  )}&title=${encodeURIComponent(job.title || '')}`}
                  title='Linkedin'
                  rel='noopener noreferrer'
                >
                  <img src={linkedinImgBt} alt='share on Linkedin button' />
                </a>
              </div>
              <div className={styles.paragraph}>
                <div className={styles.title}>Related Jobs</div>
                <div className={styles.jobContainer}>
                  {filteredJobList?.map(jobIt => (
                    <JobCard
                      job={jobIt}
                      key={jobIt.id}
                      onClick={() => navigate(`/job/${jobIt.id}?${searchParams.toString()}`)}
                    />
                  ))}
                </div>
              </div>
            </div>
            <div className={styles.right}>
              {!applicationStep ? (
                <>
                  <div className={styles.overviewJob}>
                    <div className={styles.overviewJobTitle}>Job Overview</div>
                    <div className={styles.overviewJobText}>
                      <div>Date Posted</div>
                      <IconWithText text={posted} iconName={'clockBlue'} size={17} />
                    </div>
                    <div className={styles.overviewJobText}>
                      <div>Experience</div>
                      <IconWithText text={experience} iconName={'certificates'} size={17} />
                    </div>
                    <div className={styles.overviewJobText}>
                      <div>Qualification</div>
                      <IconWithText text={job.qualification || ''} iconName={'qualification'} size={17} />
                    </div>
                    <div className={styles.overviewJobText}>
                      <div>Career Level</div>
                      <IconWithText text={job.careerLevel || ''} iconName={'careerLevel'} size={17} />
                    </div>
                  </div>
                  {/* <div className={styles.overviewJobMap}> */}
                  {/* <div className={styles.overviewJobTitle}>Job Overview</div>
                    <img src={mapImage} alt='job location map' /> */}
                  {/* </div> */}
                </>
              ) : (
                <JobApplication jobApply={jobApply} setJobApply={setJobApply} />
              )}
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div className={styles.mainLastStep}>
        {header}
        <JobApplication jobApply={jobApply} setJobApply={setJobApply} />
      </div>
    )
  }
}
