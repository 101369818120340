import React, { MouseEventHandler } from 'react'
import classNames from 'classnames'
import styles from './Button.module.scss'

interface Props {
  small?: boolean
  medium?: boolean
  large?: boolean
  basic?: boolean
  secondary?: boolean
  primary?: boolean
  text: string
  link?: boolean
  onClick?: MouseEventHandler<HTMLButtonElement>
  className?: string
  disabled?: boolean
}

export default function Button({
  small,
  medium,
  large,
  basic,
  secondary,
  primary,
  text,
  link,
  onClick,
  className,
  disabled,
}: Props) {
  if (!small && medium && large) {
    console.error('Small or medium or large is required in button component')
    return null
  }

  if (!basic && secondary && primary) {
    console.error('Basic or secondary or primary is required in button component')
    return null
  }

  const btnClassName = classNames({
    [styles.btn]: true,
    [styles.small]: small,
    [styles.medium]: medium,
    [styles.large]: large,
    [styles.basic]: basic,
    [styles.secondary]: secondary,
    [styles.primary]: primary,
    [styles.link]: link,
    [className as string]: !!className,
  })

  return (
    <button className={btnClassName} onClick={onClick} disabled={disabled}>
      {text}
    </button>
  )
}
