import classNames from 'classnames'
import { useState } from 'react'
import usePagination, { DOTS } from './hooks/usePagination'
import styles from './Pagination.module.scss'

interface Props {
  onPageChange: (pageNr: number) => void
  totalCount: number
  siblingCount: number
  initialCurrentPage: number
  pageSize: number
  page?: number
}

export default function Pagination({
  onPageChange,
  totalCount,
  siblingCount,
  initialCurrentPage,
  pageSize,
  page,
}: Props) {
  const [statePage, setCurrentPage] = useState(initialCurrentPage)
  const currentPage = page || statePage
  const totalPageCount = Math.ceil(totalCount / pageSize)
  const pages = usePagination({ siblingCount, totalPageCount, currentPage })

  function onClick(newPage: number) {
    setCurrentPage(newPage)
    onPageChange(newPage)
  }
  if (totalPageCount < 2) {
    return null
  }
  return (
    <div className={styles.pages}>
      {pages.map((pageNr, idx) => {
        if (pageNr === DOTS) {
          return <div className={styles.dots}>. . .</div>
        }
        return (
          <div
            key={idx}
            className={classNames(styles.page, { [styles.selected]: pageNr == currentPage })}
            onClick={() => onClick(pageNr as number)}
          >
            {pageNr}
          </div>
        )
      })}
      <button
        className={styles.arrowRight}
        onClick={() => onClick(currentPage + 1)}
        disabled={currentPage == totalPageCount}
      >
        {' '}
        &gt;{' '}
      </button>
    </div>
  )
}

Pagination.defaultProps = {
  siblingCount: 1,
  initialCurrentPage: 1,
}
