import { AxiosError } from 'axios'

function isAxiosError(error: unknown): error is AxiosError<ErrorResponse> {
  return error instanceof AxiosError ? error.isAxiosError : false
}

interface ErrorResponse {
  detail: string
  code?: number
}

type Props = {
  error: AxiosError<ErrorResponse> | Error
}

const ErrorMessage: React.FC<Props> = ({ error }) => {
  let message: string
  let statusCode: number | undefined

  if (isAxiosError(error)) {
    // If it's an AxiosError, extract message and status code from the response
    message = error.response?.data?.detail || error.message
    statusCode = error.response?.status
  } else {
    // It's a generic Error object
    message = error.message
  }

  // Customize the displayed message based on environment
  const errorMessage =
    process.env.NODE_ENV === 'development'
      ? `Error: ${message} (Status code: ${statusCode})`
      : 'Something went wrong. Please try again later.'

  return <div>{errorMessage}</div>
}

export default ErrorMessage

// Usage
// if (isLoading) return <div>Loading...</div>
// if (isError) return <ErrorMessage error={error} />
