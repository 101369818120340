import { parsePhoneNumberFromString } from 'libphonenumber-js'
import React from 'react'

import imagePDF from '../../assets/PDFdoc.png'
import userPic from '../../assets/user_candidate_Profile1.png'
import { JobApplicationWithCandidate } from '../../schemas/schemas'
import Button from '../Button/Button'
import Icon from '../Icon/Icon'
import styles from './InfoApplicationPanel.module.scss'

interface InfoApplicationPanelProps {
  isOpen: boolean
  onClose: () => void
  application: JobApplicationWithCandidate
}

function formatPhoneNumber(phoneNumberString: string) {
  const phoneNumber = parsePhoneNumberFromString(phoneNumberString)
  if (!phoneNumber) return phoneNumberString // Return original string if parsing fails
  return phoneNumber.formatInternational()
}

const InfoApplicationPanel: React.FC<InfoApplicationPanelProps> = ({ isOpen, onClose, application }) => {
  // Function to handle the profile navigation
  const handleProfileClick = () => {
    if (application?.candidate?.id) {
      window.location.href = `/profile/${application.candidate.id}`
    }
  }

  return (
    <div className={`${styles.overlay} ${isOpen ? styles.show : ''}`} onClick={onClose}>
      <div className={`${styles.panel} ${isOpen ? '' : styles.closed}`} onClick={e => e.stopPropagation()}>
        <div className={styles.panelTop}>
          <button className={styles.closeButton} onClick={onClose}>
            <Icon iconName='arrowBack' size={15} />
          </button>
        </div>
        <div className={styles.top}>
          <img className={styles.profilePicture} src={application?.candidate?.avatar || userPic} alt='Profile' />
          <div className={styles.profileDetails}>
            <div className={styles.name}>
              {application?.candidate?.firstName} {application?.candidate?.lastName}
            </div>
            {application?.candidate?.location && (
              <div className={styles.location}>
                <Icon iconName={'arrowOrange'} size={12} />
                <span>{application?.candidate?.location}</span>
              </div>
            )}
          </div>
          <div>
            <Button primary medium text='See Profile' onClick={handleProfileClick} />
          </div>
        </div>

        <div className={styles.applicationDetails}>
          <div className={styles.contactInf}>
            <div className={styles.header}>
              <p>Contact information</p>
            </div>
            <div className={styles.twoColumns}>
              <div>
                <p className={styles.title}>Full Name</p>
                <p className={styles.content}>
                  {application?.candidate?.firstName} {application?.candidate?.lastName}
                </p>
              </div>
              <div>
                <p className={styles.title}>Email Address</p>
                <p className={styles.content}>{application?.candidate?.email}</p>
              </div>
              <div>
                <p className={styles.title}>Location</p>
                <p className={styles.content}>{application?.candidate?.location}</p>
              </div>
              <div>
                <p className={styles.title}>Phone number</p>
                <p className={styles.content}>
                  {application?.candidate?.phone && formatPhoneNumber(application?.candidate?.phone)}
                </p>
              </div>
            </div>
          </div>
          {!!application?.resume && (
            <div className={styles.cv}>
              <div className={styles.header}>
                <p>CV/ Resume</p>
              </div>
              <div className={styles.file}>
                <img src={imagePDF} alt='imagePDF' onClick={() => window.open(application?.resume ?? '#', '_blank')} />
              </div>
            </div>
          )}
          <div className={styles.relExp}>
            <div className={styles.header}>
              <p className={styles.title}>Relevant Experience</p>
            </div>
            <div className={styles.jobs}>
              {!!application?.relatedExperiences?.length ? (
                application.relatedExperiences.map((job, index) => (
                  <div key={index}>
                    <p>Company: {job.company}</p>
                    <p className={styles.title}>Job title: {job.position}</p>
                  </div>
                ))
              ) : (
                <p>No relevant Experience</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InfoApplicationPanel
