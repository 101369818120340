import { useMutation, useQueryClient } from '@tanstack/react-query'
import Dialog from 'rc-dialog'
import { useEffect, useState } from 'react'
import { createEducation, updateEducation } from '../../api/api'
import Button from '../../components/Button/Button'
import Input from '../../components/Input/Input'
import { useUser } from '../../hooks/useUser'
import { Education, EducationSchema } from '../../schemas/schemas'
// import styles from './ProfilePage.module.scss'
import axios from 'axios'
import { DateTime } from 'luxon'
import DatePicker from 'react-date-picker'
import { ZodError, z } from 'zod'
import Icon from '../../components/Icon/Icon'
import Spinner from '../../components/Spinner/Spinner'
import TextArea from '../../components/TextArea/TextArea'
import { useDeleteEducationMutation } from '../../hooks/useDeleteEducationMutation'
import { formatDateRange } from '../../utils/date'
import styles from './ManageEducations.module.scss'

interface Props {
  userId: number
  onClose: () => void
}

// Override
export const EducationEditSchema = EducationSchema.extend({
  id: z.number(),
  userId: z.number(),
  startDate: z.string().min(1, { message: 'Please enter startDate' }),
  endDate: z.string().nullable(),
  degree: z.string().min(1, { message: 'Please enter degree' }),
  institution: z.string().min(1, { message: 'Please enter institution name' }),
  description: z.string().min(1, { message: 'Please enter description' }),
})

export const EducationCreateSchema = EducationEditSchema.extend({
  id: z.number().optional(),
})

export type EducationEdit = z.infer<typeof EducationEditSchema>
export type EducationCreate = z.infer<typeof EducationCreateSchema>

export const Errors = ({ data }: { data?: string[] }) => {
  if (!data || data.length === 0) return null
  if (data.length == 1) return <div className={styles.red}>{data?.[0]}</div>
  return (
    <div className={styles.red}>
      <ul>
        {data.map((errorMessage, index) => (
          <li key={index}>{errorMessage}</li>
        ))}
      </ul>
    </div>
  )
}

export default function ManageEducations({ onClose, userId }: Props) {
  const { data: user } = useUser(userId)
  const [editEducationId, setEditEducationId] = useState<number | null>(null)
  const [addEducation, setAddEducation] = useState(false)
  const [errors, setErrors] = useState<Partial<Record<keyof Education, string[]>>>({})
  const queryClient = useQueryClient()
  const deleteEducationMutation = useDeleteEducationMutation(userId)

  const [startDate, setStartDate] = useState<Date>()
  const [endDate, setEndDate] = useState<Date>()
  const [institution, setInstitution] = useState<string>('')
  const [degree, setDegree] = useState<string>('')
  const [description, setDescription] = useState<string>('')

  const updateEducationMutation = useMutation({
    mutationFn: updateEducation,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['user', userId] })
    },
  })
  const createEducationMutation = useMutation({
    mutationFn: createEducation,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['user', userId] })
    },
  })

  useEffect(() => {
    if (editEducationId) {
      const editEducation = user?.educations?.find(it => it.id === editEducationId)
      if (editEducation) {
        setStartDate(editEducation.startDate ? DateTime.fromISO(editEducation.startDate).toJSDate() : undefined)
        setEndDate(editEducation.endDate ? DateTime.fromISO(editEducation.endDate).toJSDate() : undefined)
        setInstitution(editEducation.institution || '')
        setDegree(editEducation.degree || '')
        setDescription(editEducation.description || '')
      }
    }
  }, [editEducationId])

  useEffect(() => {
    if (addEducation) {
      setStartDate(undefined)
      setEndDate(undefined)
      setInstitution('')
      setDegree('')
      setDescription('')
    }
  }, [addEducation])

  const handleErrors = (error: unknown) => {
    if (error instanceof ZodError) {
      setErrors(error.flatten().fieldErrors)
    } else if (axios.isAxiosError(error)) {
      console.log('Network error:', error.message)
    } else {
      console.error('Unexpected error:', error)
    }
  }

  const handleEditEducation = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    if (!editEducationId) {
      setErrors({ description: ['Missing Education id'] })
      return
    }

    const editPayload: EducationEdit = {
      id: editEducationId,
      userId,
      institution,
      degree,
      description,
      startDate: startDate ? DateTime.fromJSDate(startDate).toISODate() || '' : '',
      endDate: endDate ? DateTime.fromJSDate(endDate).toISODate() : null,
    }

    try {
      EducationEditSchema.parse(editPayload)
      await updateEducationMutation.mutateAsync(editPayload)
      setEditEducationId(null)
      setErrors({})
    } catch (error) {
      handleErrors(error)
    }
  }

  const handleAddEditEducation = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    const addPayload: EducationCreate = {
      userId,
      institution,
      degree,
      description,
      startDate: startDate ? DateTime.fromJSDate(startDate).toISODate() || '' : '',
      endDate: endDate ? DateTime.fromJSDate(endDate).toISODate() : '',
    }

    try {
      EducationCreateSchema.parse(addPayload)
      await createEducationMutation.mutateAsync(addPayload)
      setAddEducation(false)
      setErrors({})
    } catch (error) {
      handleErrors(error)
    }
  }

  const handleDeleteEducation = async (expId: number) => {
    if (window.confirm('Are you sure you want to delete this Education?')) {
      await deleteEducationMutation.mutateAsync(expId)
    }
  }

  return (
    <>
      {updateEducationMutation.isPending && <Spinner />}

      {/* List modal */}
      <Dialog
        title='Available Educations for edit'
        onClose={onClose}
        className={styles.dialogEducationsModal}
        visible={true}
        maskAnimation='fade'
        style={{ top: '30%' }}
      >
        {user?.educations?.map(exp => (
          <tr key={exp.id} className={styles.item}>
            <td>{formatDateRange(exp.startDate, exp.endDate)}</td>
            <td>{exp.institution}</td>
            <td>{exp.degree}</td>
            <td>
              <button
                onClick={() => setEditEducationId(exp.id)}
                className={`${styles.actionButton} ${styles.editButton}`}
              >
                <Icon iconName={'pencilEditOrange'} />
              </button>
              <button onClick={() => handleDeleteEducation(exp.id)} className={styles.actionButton}>
                {' '}
                <Icon iconName={'trash'} />{' '}
              </button>
            </td>
          </tr>
        ))}
        <div className={styles.footer}>
          <Button primary medium text='Add' onClick={() => setAddEducation(true)} />
        </div>
      </Dialog>

      {/* Add / edit modal  */}
      <Dialog
        title={addEducation ? 'Add Education' : 'Edit Education'}
        onClose={() => (addEducation ? setAddEducation(false) : setEditEducationId(null))}
        visible={addEducation || editEducationId != null}
        className={styles.shortWidth}
        maskAnimation='fade'
        style={{ top: '40%' }}
      >
        <form onSubmit={editEducationId ? handleEditEducation : handleAddEditEducation} className={styles.formLayout}>
          <div>
            <label>Start date</label>
            <DatePicker onChange={d => setStartDate(d as Date)} value={startDate} maxDate={new Date()} />
            <Errors data={errors?.startDate} />
          </div>
          <div>
            <label>End date</label>
            <DatePicker onChange={d => setEndDate(d as Date)} value={endDate} />
            <Errors data={errors?.endDate} />
          </div>
          <div>
            <label>Institution</label>
            <Input value={institution} onChange={e => setInstitution(e.target.value)} />
            <Errors data={errors?.institution} />
          </div>

          <div>
            <label>Degree</label>
            <Input value={degree} onChange={e => setDegree(e.target.value)} />
            <Errors data={errors?.degree} />
          </div>

          <div>
            <label>Description</label>
            <TextArea value={description} onChange={e => setDescription(e.target.value)} />
            <Errors data={errors?.description} />
          </div>

          <div className={styles.footer}>
            <Button primary medium text='Save' />
          </div>
        </form>
      </Dialog>
    </>
  )
}
