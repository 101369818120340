import { useMutation } from '@tanstack/react-query'
import { createJob, updateJob } from '../api/api'
import { Job, JobCreate } from '../schemas/schemas'

export const useJobMutation = () => {
  return useMutation({
    mutationFn: (formData: Job | JobCreate) => {
      if ('id' in formData && formData.id !== undefined) {
        return updateJob(formData as Job)
      } else {
        return createJob(formData as JobCreate)
      }
    },
    onSuccess: () => {
      // queryClient.invalidateQueries({ queryKey: ['jobList'] })
    },
  })
}
