import { useSearchParams } from 'react-router-dom'

export interface Filters {
  matchMe?: boolean
  industry?: string
  jobType?: string
  posted?: string
  pay?: [number, number]
  experience?: string[]
  title?: string
  location?: string
  sortBy?: string
  page?: number
}

const defaultFilters: Filters = {
  matchMe: false,
  industry: undefined,
  jobType: undefined,
  posted: undefined,
  pay: [0, 200000],
  experience: [],
  title: undefined,
  location: undefined,
  sortBy: 'default',
  page: 1,
}

export default function useSearchFilters() {
  const [searchParams, setSearchParams] = useSearchParams()

  const filters: Filters = {
    matchMe: searchParams.get('matchMe') == 'true',
    title: searchParams.get('title') || undefined,
    location: searchParams.get('location') || undefined,
    industry: searchParams.get('industry') || undefined,
    jobType: searchParams.get('jobType') || undefined,
    pay:
      (searchParams
        .get('pay')
        ?.split(',')
        ?.map(item => parseInt(item)) as [number, number]) || defaultFilters.pay,
    posted: searchParams.get('posted') || undefined,
    experience: searchParams.get('experience')?.split(','),
    sortBy: searchParams.get('sortBy') || undefined,
    page: searchParams.has('page') ? Number(searchParams.get('page')) : 1,
  }

  const setFilters = (newFilters: Partial<Filters>) => {
    let pageResetNeeded = false

    Object.entries(newFilters).forEach(([key, value]) => {
      if (key !== 'sortBy' && key !== 'page' && filters[key as keyof Filters] !== value) {
        pageResetNeeded = true
      }

      if ((value && !Array.isArray(value)) || (Array.isArray(value) && value.length > 0)) {
        searchParams.set(key, Array.isArray(value) ? value.join(',') : String(value))
      } else {
        searchParams.delete(key)
      }
    })

    if (pageResetNeeded) {
      searchParams.delete('page')
    }

    setSearchParams(searchParams)
  }

  const clearFilters = () => {
    setSearchParams(new URLSearchParams())
  }

  return { filters, setFilters, clearFilters }
}
