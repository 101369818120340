import { EditorState } from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'
import { stateFromHTML } from 'draft-js-import-html'
import { DateTime } from 'luxon'
import Dialog from 'rc-dialog'
import { SyntheticEvent, useEffect, useState } from 'react'
import DatePicker from 'react-date-picker'
import { Editor } from 'react-draft-wysiwyg'
import PhoneInput from 'react-phone-input-2'
import { useNavigate } from 'react-router-dom'
import { ZodError, z } from 'zod'
import { uploadFileAndGetURL } from '../../api/api'
import Button from '../../components/Button/Button'
import { Color } from '../../components/Dropdown/Dropdown'
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage'
import Icon from '../../components/Icon/Icon'
import Input, { Size } from '../../components/Input/Input'
import Spinner from '../../components/Spinner/Spinner'
import Uploader from '../../components/Uploader/Uploader'
import { useCurrentCompany } from '../../hooks/useCompany'
import { useCompanyUpdateMutation } from '../../hooks/useCompanyUpdateMutation'
import { Company, CompanySchema } from '../../schemas/schemas'
import { TOOLBAR_CONFIG } from '../../utils/editor'
import styles from './CompanyProfilePage.module.scss'

export default function CompanyProfilePage() {
  const navigate = useNavigate()
  const { isPending, isError, data: company, error } = useCurrentCompany()

  const companyUpdateMutation = useCompanyUpdateMutation()

  // const [croppedLogoBlob, setCroppedLogoBlob] = useState<Blob | null>(null)
  const [croppedLogoUrl, setCroppedLogoUrl] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [companyEmail, setCompanyEmail] = useState('')
  const [companyPhone, setCompanyPhone] = useState('')
  const [companyWebsite, setCompanyWebsite] = useState('')
  const [since, setSince] = useState<Date | null>(null)
  const [aboutCompanyEditorState, setAboutCompanyEditorState] = useState(
    EditorState.createWithContent(stateFromHTML('')),
  )
  const [facebook, setFacebook] = useState('')
  const [twitter, setTwitter] = useState('')
  const [linkedin, setLinkedin] = useState('')
  const [addressCompany, setAddressCompany] = useState('')

  const [errors, setErrors] = useState<ZodError | null>()
  const [isSuccessDialogVisible, setIsSuccessDialogVisible] = useState(false)

  useEffect(() => {
    if (company) {
      setCompanyName(company.name || '')
      setCompanyEmail(company.email || '')
      setCompanyPhone(company.phone || '')
      setCompanyWebsite(company.website || '')
      setSince(company.founded ? DateTime.fromISO(company.founded).toJSDate() : null)
      setFacebook(company.facebook || '')
      setTwitter(company.twitter || '')
      setAddressCompany(company.location || '')
      setLinkedin(company.linkedin || '')
      setAboutCompanyEditorState(EditorState.createWithContent(stateFromHTML(company.about || '')))
      if (company.logo) {
        setCroppedLogoUrl(company.logo)
      }
    }
  }, [company])

  const handleSubmit = async (e: SyntheticEvent) => {
    e.preventDefault()

    if (!company) {
      console.error('Company not loaded')
      return
    }

    const getHtml = (state: EditorState) => {
      const html = stateToHTML(state.getCurrentContent()).replace('<p><br></p>', '')
      return html || undefined
    }

    const companyUpdate = {
      id: company.id,
      name: companyName,
      email: companyEmail || null,
      phone: companyPhone,
      website: companyWebsite,
      founded: since ? DateTime.fromJSDate(since).toISODate() : null,
      location: addressCompany,
      about: getHtml(aboutCompanyEditorState) || '',
      facebook: facebook,
      twitter: twitter,
      linkedin: linkedin,
    }

    try {
      CompanySchema.parse(companyUpdate)
      setErrors(null)
      await companyUpdateMutation.mutateAsync(companyUpdate)
      setIsSuccessDialogVisible(true)
    } catch (err) {
      console.error(err)
      if (err instanceof z.ZodError) {
        setErrors(err)

        // scroll to error
        const firstError = err.issues[0].message
        const foundElement = Array.from(document.querySelectorAll('p')).find(el => el.textContent === firstError)
        foundElement?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'start',
        })
      }
    }
  }

  const updateLogo = async (fileName: string, file: Blob, fileType: string) => {
    if (!file) {
      alert('Please select a file to upload.')
      return
    }

    if (!company) {
      console.error('Company not loaded')
      return
    }

    try {
      const newLogoUrl = await uploadFileAndGetURL(fileName, file, fileType)

      // Step 3: Update the user's avatar URL in the backend
      const updateCompanyLogoPayload: Company = { id: company?.id, name: company?.name || '', logo: newLogoUrl }
      companyUpdateMutation.mutate(updateCompanyLogoPayload)
    } catch (updateLogoError) {
      console.error('Error updating logo:', updateLogoError)
    }
  }

  const handleCropComplete = (blobUrl: string, blob: Blob, fileType: string) => {
    setCroppedLogoUrl(blobUrl)
    // setCroppedLogoBlob(blob)
    updateLogo(`company-logo-${company?.id}`, blob, fileType)
  }

  const handleChangeLogo = (e: SyntheticEvent) => {
    e.preventDefault()
    // setCroppedLogoBlob(null)
    setCroppedLogoUrl('')
  }

  if (isPending) return <span>Loading...</span>
  if (isError) return <ErrorMessage error={error} />

  return (
    <div className={styles.compProfPage}>
      {companyUpdateMutation.isPending && <Spinner />}

      <h1>Company Profile</h1>
      <p className={styles.explain}>Please fill the complete information regarding your company profile.</p>
      <div className={styles.card1}>
        <div>
          <h3>Profile Info</h3>
        </div>
        <form className={styles.form} onSubmit={handleSubmit}>
          <div className={styles.upload}>
            {!croppedLogoUrl && <Uploader onCropComplete={handleCropComplete} />}
            {croppedLogoUrl && (
              <div className={styles.croppedLogoBlock}>
                <img src={croppedLogoUrl} alt='Cropped' />
                <button className={styles.buttonLogo} onClick={handleChangeLogo}>
                  Change Logo
                </button>
              </div>
            )}
          </div>
          <p className={styles.suggest}>
            After uploading/changing the logo scroll all the way down and push the button Save on the bottom of the page
          </p>

          <div className={styles.concise}>
            <div className={styles.contactDetails}>
              <div className={styles.jobTitles}>
                <label>Company Name*</label>
                <Input
                  value={companyName}
                  onChange={e => setCompanyName(e.target.value)}
                  size={Size.Large}
                  bgColor={Color.Gray}
                  errors={errors?.formErrors.fieldErrors.name}
                />
              </div>
              <div className={styles.jobTitles}>
                <label>Email address</label>
                <Input
                  placeholder='Example: email@gmail.com'
                  value={companyEmail}
                  onChange={e => setCompanyEmail(e.target.value)}
                  size={Size.Large}
                  bgColor={Color.Gray}
                  errors={errors?.formErrors.fieldErrors.email}
                />
              </div>
              <div className={styles.jobTypes}>
                <label>Phone Number</label>
                <PhoneInput
                  inputClass={styles.phoneInputClass}
                  buttonClass={styles.phoneButtonClass}
                  country={'us'}
                  value={companyPhone}
                  onChange={e => setCompanyPhone(e)}
                />
              </div>
              <div className={styles.jobTitles}>
                <label>Website</label>
                <Input
                  placeholder='Example: www.netsole.com'
                  value={companyWebsite}
                  onChange={e => setCompanyWebsite(e.target.value)}
                  size={Size.Large}
                  bgColor={Color.Gray}
                  errors={errors?.formErrors.fieldErrors.website}
                />
              </div>
              <div className={styles.jobTypes}>
                <label>Est. Since</label>
                <DatePicker
                  className={errors?.formErrors.fieldErrors.deadline && styles.errorBorder}
                  onChange={d => setSince(d as Date)}
                  value={since}
                  maxDate={new Date()}
                />
                {errors?.formErrors.fieldErrors.since &&
                  errors?.formErrors.fieldErrors.since.map((err, index) => (
                    <p key={index} className={styles.errorMessage}>
                      {err}
                    </p>
                  ))}
              </div>

              <div className={styles.jobTitles}>
                <label>Business Address</label>
                <Input
                  placeholder='Example: 925 Blossom Hill Rd, San Jose, CA 95123'
                  value={addressCompany}
                  onChange={e => setAddressCompany(e.target.value)}
                  size={Size.Large}
                  bgColor={Color.Gray}
                  errors={errors?.formErrors.fieldErrors.addressCompany}
                />
              </div>
            </div>
            <label>About Company</label>
            <Editor
              editorState={aboutCompanyEditorState}
              toolbarClassName={styles.toolbarClassName}
              wrapperClassName={
                errors?.formErrors.fieldErrors.aboutCompany ? styles.wrapperClassNameError : styles.wrapperClassName
              }
              editorClassName={styles.editorClassName}
              onEditorStateChange={state => setAboutCompanyEditorState(state)}
              toolbar={TOOLBAR_CONFIG}
            />
            {errors?.formErrors.fieldErrors.aboutCompany &&
              errors?.formErrors.fieldErrors.aboutCompany.map((err, index) => (
                <p key={index} className={styles.errorMessage}>
                  {err}
                </p>
              ))}

            <p className={styles.networks}>Social Networks</p>
            <div className={styles.social}>
              <div className={styles.jobTitles}>
                <label>Facebook</label>
                <Input
                  placeholder='Example: https://www.facebook.com/name'
                  value={facebook}
                  onChange={e => setFacebook(e.target.value)}
                  size={Size.Large}
                  bgColor={Color.Gray}
                  errors={errors?.formErrors.fieldErrors.facebook}
                />
              </div>
              <div className={styles.jobTitles}>
                <label>X (former Twitter)</label>
                <Input
                  placeholder='Example: https://www.x.com/name'
                  value={twitter}
                  onChange={e => setTwitter(e.target.value)}
                  size={Size.Large}
                  bgColor={Color.Gray}
                  errors={errors?.formErrors.fieldErrors.twitter}
                />
              </div>
              <div className={styles.jobTitles}>
                <label>LinkedIn</label>
                <Input
                  placeholder='Example: https://www.linkedin.com/name'
                  value={linkedin}
                  onChange={e => setLinkedin(e.target.value)}
                  size={Size.Large}
                  bgColor={Color.Gray}
                  errors={errors?.formErrors.fieldErrors.linkedin}
                />
              </div>
            </div>

            <Button
              className={styles.saveBtn}
              primary
              medium
              basic
              text={'Save'}
              disabled={companyUpdateMutation.isPending}
            />
          </div>
        </form>
      </div>
      {isSuccessDialogVisible && (
        <Dialog
          title='Success'
          visible
          className={styles.center}
          maskAnimation='fade'
          onClose={() => setIsSuccessDialogVisible(false)}
        >
          <div className={styles.success_window}>
            <Icon iconName={'success'} size={100} />
            <p id='message' className={styles.success_message}>
              Congratulations, your Company Profile was successfully Updated.
            </p>
            <div>
              <Button
                primary
                medium
                text='Post a New Job'
                onClick={() => {
                  navigate(`/emp/post-job`)
                }}
              />
            </div>
          </div>
        </Dialog>
      )}
    </div>
  )
}
