import React from 'react'
import styles from './NotFoundPage.module.scss'

const NotFoundPage: React.FC = () => {
  return (
    <div className={styles.notFoundContainer}>
      <h1>404 Not Found</h1>
      <p>The page you're looking for doesn't exist.</p>
    </div>
  )
}

export default NotFoundPage
