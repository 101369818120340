import React, { ReactElement, useState } from 'react'
import useOutsideClick from '../../hooks/OutsideClick'
import { Props as IconProps } from '../Icon/Icon'
import styles from './AutocompleteSearch.module.scss'
import classNames from 'classnames'

export enum Color {
  White = 'WHITE',
  Gray = 'GRAY',
}

interface Props {
  onChange: (word: string) => void
  placeholder?: string
  data: string[]
  icon?: ReactElement<IconProps>
  value: string
  className?: string
  bgColor?: Color
}

const MAX_RESULTS = 10

const AutocompleteSearch: React.FC<Props> = ({ onChange, placeholder, data, icon, value, className, bgColor }) => {
  const [results, setResults] = useState<string[]>([])
  const [error, setError] = useState<string>('')
  const ref = useOutsideClick(() => {
    setResults([])
    setError('')
  })

  const inputClasses = classNames(
    styles.autocomplete,
    {
      [styles.gray]: bgColor === Color.Gray,
    },
    className,
  )

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const term = event.target.value
    onChange(term)
    if (term.length > 0) {
      const filteredResults = data.filter(item => item.toLowerCase().includes(term.toLowerCase()))
      setResults(filteredResults.slice(0, MAX_RESULTS))
    } else {
      setResults([])
    }
  }

  const handleItemClick = (item: string) => {
    onChange(item)
    setResults([])
  }

  return (
    <div className={inputClasses} ref={ref}>
      <div className={styles.iconText}>
        {icon}
        <input type='text' placeholder={placeholder} value={value} onChange={handleInputChange} />
      </div>

      {error && <p className={styles.error}>{error}</p>}

      {results.length > 0 && (
        <ul className={styles.list}>
          {results.map(result => (
            <li key={result} onClick={() => handleItemClick(result)}>
              {result}
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

AutocompleteSearch.defaultProps = {
  data: [],
}

export default AutocompleteSearch
