import { useEffect, DependencyList } from 'react'

// export function useDebounceEffect(fn: () => void, waitTime: number, deps?: DependencyList) {
//   useEffect(() => {
//     const t = setTimeout(() => {
//       fn.apply(undefined, deps)
//     }, waitTime)

//     return () => {
//       clearTimeout(t)
//     }
//   }, deps)
// }

export function useDebounceEffect(fn: (...args: DependencyList) => void, waitTime: number, deps?: DependencyList) {
  useEffect(() => {
    const t = setTimeout(() => {
      fn(...(deps || []))
    }, waitTime)

    return () => {
      clearTimeout(t)
    }
  }, [fn, waitTime, ...(deps || [])])
}
