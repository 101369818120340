import React, { useState } from 'react'
import styles from './EmployerManageJobs.module.scss'
import rocket from '../../assets/rocket.png'
import { useAuth } from '../../context/AuthContext'
import { fetchJobsApplicationsAndUsers } from '../../api/api'
import Dropdown, { Option } from '../../components/Dropdown/Dropdown'
import Icon from '../../components/Icon/Icon'
import Pagination from '../../components/Pagination/Pagination'
import { formatDate } from '../../utils/date'
import { useCurrentUser } from '../../hooks/useCurrentUser'
import { Link } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { useDeleteJobMutation } from '../../hooks/useDeleteJobMutation'
import Spinner from '../../components/Spinner/Spinner'
import { DateTime } from 'luxon' // Import DateTime from luxon
import { JobWithApplicationsAndUsers } from '../../schemas/schemas'

const EmployerManageJobs = () => {
  const { isAuthenticated } = useAuth()
  const { data: user } = useCurrentUser()

  // State for the selected period
  const [selectedPeriod, setSelectedPeriod] = useState<string>('')

  // Query
  const { data, isFetching } = useQuery({
    queryKey: ['jobsApplicationsUsers'],
    queryFn: () => fetchJobsApplicationsAndUsers(user?.id || 0),
    enabled: isAuthenticated && !!user,
  })

  // Mutation
  const deleteJobMutation = useDeleteJobMutation()

  const handleDeleteJob = async (jobId: number) => {
    if (window.confirm('Are you sure you want to delete this job?')) {
      await deleteJobMutation.mutateAsync(jobId)
    }
  }

  const dropdownOptions: Option[] = [
    { id: '1', value: 'Last 24 hours' },
    { id: '2', value: 'Last 6 Months' },
    { id: '3', value: 'Last Year' },
  ]

  const [, setPage] = useState(1)

  const filterJobsByPeriod = (jobs: JobWithApplicationsAndUsers[], period: string) => {
    if (!period) return jobs

    const now = DateTime.now()

    switch (period) {
      case 'Last 24 hours':
        return jobs.filter(job => job.createdAt && DateTime.fromISO(job.createdAt).plus({ days: 1 }) >= now)
      case 'Last 6 Months':
        return jobs.filter(job => job.createdAt && DateTime.fromISO(job.createdAt).plus({ months: 6 }) >= now)
      case 'Last Year':
        return jobs.filter(job => job.createdAt && DateTime.fromISO(job.createdAt).plus({ years: 1 }) >= now)
      default:
        return jobs
    }
  }

  const filteredJobs = filterJobsByPeriod(data || [], selectedPeriod)

  return (
    <div className={styles.manageJobs}>
      {(isFetching || deleteJobMutation.isPending) && <Spinner />}

      <header className={styles.header}>
        <h1>Manage Jobs!</h1>
        <p>Ready to see your statistics regarding jobs and hirings?</p>
      </header>
      <div className={styles.background}>
        <h2>Manage Jobs</h2>

        <div className={styles.topData}>
          <div className={styles.statsCard}>
            <div className={styles.rocketBack}>
              <img src={rocket} alt='start' />
            </div>
            <div className={styles.statJobs}>
              <p className={styles.totalJobs}>Total Jobs Posted</p>
              <p className={styles.totalJobsCount}>{filteredJobs.length}</p>
            </div>
          </div>

          <div className={styles.jobFilter}>
            <Dropdown
              data={dropdownOptions}
              placeholder='Select period'
              onChange={(val: string) => setSelectedPeriod(val)}
              value={selectedPeriod}
            />
          </div>
        </div>

        <div className={styles.manageJobs}>
          <table className={styles.jobsTable}>
            <thead>
              <tr>
                <th>Job Title</th>
                <th>Location</th>
                <th>Created</th>
                <th>Close Date</th>
                <th>Applications received</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {filteredJobs.map(job => (
                <tr key={job.id}>
                  <td>{job.title}</td>
                  <td>{job.location}</td>
                  <td>{formatDate(job.createdAt)}</td>
                  <td>{formatDate(job.deadline)}</td>
                  <td>{`${job.applications.length} Application${job.applications.length === 1 ? '' : 's'}`}</td>
                  <td>
                    <Link className={`${styles.actionButton} ${styles.viewDetailsButton}`} to={`/job/${job.id}`}>
                      <Icon iconName={'viewDetailsBlue'} />
                    </Link>

                    <Link className={`${styles.actionButton} ${styles.editButton}`} to={`/emp/manage-jobs/${job.id}`}>
                      <Icon iconName={'pencilEditOrange'} />
                    </Link>
                    <button onClick={() => handleDeleteJob(job.id)} className={styles.actionButton}>
                      <Icon iconName={'trash'} />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {filteredJobs.length > 7 && (
          <div className={styles.pagination}>
            <Pagination onPageChange={pageNr => setPage(pageNr)} totalCount={filteredJobs.length} pageSize={7} />
          </div>
        )}
      </div>
    </div>
  )
}

export default EmployerManageJobs
