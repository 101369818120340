import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useAuth } from '../context/AuthContext'
import { logoutUser } from '../api/api'

export const useLogoutMutation = () => {
  const { revokeAuth } = useAuth()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: logoutUser,
    onSuccess: () => {
      revokeAuth()
      setTimeout(() => {
        queryClient.invalidateQueries({ queryKey: ['currentUser'] })
        queryClient.removeQueries({ queryKey: ['currentUser'] })
      }, 1000)
    },
  })
}
