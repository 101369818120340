import React from 'react'
import { useNavigate } from 'react-router-dom'
import styles from './SuccessComponent.module.scss'
import Dialog from 'rc-dialog'
import Icon from '../Icon/Icon'
import Button from '../Button/Button'

interface SuccessComponentProps {
  message: string
  onContinue: () => void // Callback for what happens when 'Continue' is clicked
  onClose: () => void
}

const SuccessComponent: React.FC<SuccessComponentProps> = ({ message, onContinue, onClose }) => {
  const navigate = useNavigate()

  const handleContinue = () => {
    navigate(`/profile/applications`)
    onContinue()
  }

  return (
    <Dialog title='Success' onClose={onClose} visible className={styles.center} maskAnimation='fade'>
      <div className={styles.success_window}>
        <Icon iconName={'success'} size={100} />
        <p className={styles.message}>{message}</p>
        <Button primary medium text='Continue' onClick={handleContinue} />
      </div>
    </Dialog>
  )
}

export default SuccessComponent
