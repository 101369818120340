import { useQuery } from '@tanstack/react-query'
import { similarJobs } from '../api/api'

export const useSimilarJobs = (jobId: number, limit: number) => {
  console.log('useSimilarJobs', jobId, limit)
  return useQuery({
    queryKey: ['similar', jobId],
    // queryFn: () => similarJobs(jobId, limit),
    queryFn: () => similarJobs(),

    refetchOnWindowFocus: false,
  })
}
