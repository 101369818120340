import React from 'react'
import styles from './RadioButtonGroup.module.scss'

type Option = {
  value: string
  label: string
}

type Props = {
  options: Option[]
  value?: string | null
  onChange: (value: string) => void
}

const RadioButtonGroup = ({ options, value, onChange }: Props) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value
    onChange(newValue)
  }
  return (
    <div className={styles.radioButtonGroup}>
      {options.map(option => (
        <div key={option.value} className={styles.radioButtonContainer}>
          <label htmlFor={option.value}>
            <input
              type='radio'
              name='radioButton'
              value={option.value}
              checked={value === option.value}
              onChange={handleChange}
              id={option.value}
            />
            <span className={value === option.value ? styles.selected : styles.unselected}></span>
            {option.label}
          </label>
        </div>
      ))}
    </div>
  )
}

export default RadioButtonGroup
