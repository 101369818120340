import React from 'react'
import styles from './Logo.module.scss'
import { useNavigate } from 'react-router-dom'

export default function Logo() {
  const navigate = useNavigate()

  const onClickHandler = () => {
    navigate('/')
  }
  return (
    <div className={styles.logo} onClick={onClickHandler}>
      <h2 className={styles.blue}>
        <span className={styles.orange}>&lt;/</span>pavedit<span className={styles.orange}>&gt;</span>
      </h2>
    </div>
  )
}
