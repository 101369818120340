import { useQuery } from '@tanstack/react-query'
import { useAuth } from '../context/AuthContext'
import { getProfile } from '../api/api'
import { AxiosError } from 'axios'

export const useUser = (userId: number) => {
  const { isAuthenticated } = useAuth()

  return useQuery({
    queryKey: ['user', userId],
    queryFn: () => getProfile(userId),
    staleTime: 1000 * 60 * 60, // 60 minutes
    enabled: isAuthenticated,
    retry: (failureCount, error: AxiosError) => {
      // Axios embeds HTTP status in the error object
      if (error.response && error.response.status === 404) {
        return false
      }
      return failureCount < 3
    },
  })
}
