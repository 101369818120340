import React from 'react'
import styles from './JobCard.module.scss'
import Badge, { BadgeType } from '../Badge/Badge'
import IconWithText from '../IconWithText/IconWithText'
import { JobWithCompany } from '../../schemas/schemas'
interface Props {
  job: JobWithCompany
  onClick: (event: React.SyntheticEvent) => void
}

export default function JobCard({ onClick, job }: Props) {
  const pay = `$${job.payFrom?.toLocaleString('en-US')}-$${job.payTo?.toLocaleString('en-US')}`

  return (
    <div className={styles.jobCardContainer} onClick={onClick}>
      <img className={styles.jobCardIcon} src={job?.company?.logo} alt='sample_icon' />

      <div className={styles.jobCardTextContent}>
        <div className={styles.jobCardTextTitle}>
          <div className={styles.title}>{job.title}</div>
          <Badge text={job.jobType || ''} type={job.jobType == 'Full Time' ? BadgeType.Blue : BadgeType.Yellow} />
          <div className={styles.empty} />
          {/* <div
            className={isBookmarked ? styles.backgroundBookmarkBlue : styles.backgroundBookmarkGrey}
            onClick={e => handleBookmarkOnClick(e)}
          > */}
          {/* <Icon
              iconName={isBookmarked ? 'bookmarkWhite' : 'bookmark'}
              color={'black'}
              backgroundColor={isBookmarked ? 'white' : 'gray'}
              size={17}
            /> */}
          {/* </div> */}
        </div>

        <div className={styles.jobCardTextCompanyName}>{job?.company?.name}</div>
        <div className={styles.jobLocationPay}>
          <IconWithText text={job.location || ''} iconName={'location'} size={18} />
          <IconWithText text={`${pay} / year`} iconName={'pay'} size={20} />
        </div>
      </div>
    </div>
  )
}
