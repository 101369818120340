import { useQuery } from '@tanstack/react-query'
import { useAuth } from '../context/AuthContext'
import { getCurrentUser } from '../api/api'

export const useCurrentUser = () => {
  const { isAuthenticated } = useAuth()

  return useQuery({
    queryKey: ['currentUser'],
    queryFn: getCurrentUser,
    staleTime: 1000 * 60 * 60, // 60 minutes
    enabled: isAuthenticated,
  })
}
