import React from 'react'
import styles from './Icon.module.scss'
import classNames from 'classnames'
import backArr from './svg icons/backArr.svg'
import bag from './svg icons/bag.svg'
import bellNotif from './svg icons/bellNotif.svg'
import bookmark from './svg icons/bookmark.svg'
import bookmarkWhite from './svg icons/bookmarkWhite.svg'
import calendar from './svg icons/calendar.svg'
import careerLevel from './svg icons/careerLevel.svg'
import certificates from './svg icons/certificates.svg'
import clock from './svg icons/clock.svg'
import clockBlue from './svg icons/clockBlue.svg'
import companyBuilding from './svg icons/companyBuilding.svg'
import copy from './svg icons/copy.svg'
import dashboardView from './svg icons/dashboardView.svg'
import employer from './svg icons/employer.svg'
import envelope from './svg icons/envelope.svg'
import filePDF from './svg icons/filePDF.svg'
import location from './svg icons/location.svg'
import lockedProfile from './svg icons/lockedProfile.svg'
import logout from './svg icons/logout.svg'
import navigationOrangeArrow from './svg icons/navigationOrangeArrow.svg'
import paperPlane from './svg icons/paperPlane.svg'
import pencilEdit from './svg icons/pencilEdit.svg'
import personThinkingillustration from './svg icons/personThinkingillustration.svg'
import profileFillBlack from './svg icons/profileFillBlack.svg'
import profileFillNone from './svg icons/profileFillNone.svg'
import qualification from './svg icons/qualification.svg'
import questionMarkHelp from './svg icons/questionMarkHelp.svg'
import salary from './svg icons/salary.svg'
import search from './svg icons/search.svg'
import arrowDown from './svg icons/arrowDown.svg'
import arrowDownGray from './svg icons/arrowDownGray.svg'
import fullArrowDown from './svg icons/fullArrowDown.svg'
import arrowOrange from './svg icons/arrowOrange.svg'
import menu from './svg icons/menu.svg'
import bluePaperPlane from './svg icons/bluePaperPlane.svg'
import papers from './svg icons/papers.svg'
import settings from './svg icons/settings.svg'
import world from './svg icons/world.svg'
import door_out from './svg icons/door-out.svg'
import success from './svg icons/successCheckMark.svg'
import arrowUp from './svg icons/arrowUp.svg'
import arrowBack from './svg icons/backArr.svg'
import greenFile from './svg icons/greenFile.svg'
import trash from './svg icons/trash.svg'
import viewDetails from './svg icons/viewDetails.svg'
import openInNewWindow from './svg icons/openInNewWindow.svg'
import viewDetailsBlue from './svg icons/viewDetailsBlue.svg'
import pencilEditOrange from './svg icons/pencilEditOrange.svg'

const srcMap: { [key: string]: string } = {
  arrowDownGray: arrowDownGray,
  backArr: backArr,
  bag: bag,
  bellNotif: bellNotif,
  bookmark: bookmark,
  bookmarkWhite: bookmarkWhite,
  bluePaperPlane: bluePaperPlane,
  calendar: calendar,
  careerLevel: careerLevel,
  certificates: certificates,
  clock: clock,
  clockBlue: clockBlue,
  companyBuilding: companyBuilding,
  copy: copy,
  dashboardView: dashboardView,
  employer: employer,
  envelope: envelope,
  filePDF: filePDF,
  location: location,
  lockedProfile: lockedProfile,
  logout: logout,
  navigationOrangeArrow: navigationOrangeArrow,
  papers: papers,
  paperPlane: paperPlane,
  pencilEdit: pencilEdit,
  pencilEditOrange: pencilEditOrange,
  personThinkingillustration: personThinkingillustration,
  profileFillBlack: profileFillBlack,
  profileFillNone: profileFillNone,
  qualification: qualification,
  questionMarkHelp: questionMarkHelp,
  pay: salary,
  arrowDown: arrowDown,
  fullArrowDown: fullArrowDown,
  arrowOrange: arrowOrange,
  search: search,
  menu: menu,
  settings: settings,
  world: world,
  door_out: door_out,
  success: success,
  arrowUp: arrowUp,
  arrowBack: arrowBack,
  greenFile: greenFile,
  trash: trash,
  viewDetails: viewDetails,
  openInNewWindow: openInNewWindow,
  viewDetailsBlue: viewDetailsBlue,
}

export interface Props {
  iconName: string
  color?: string
  backgroundColor?: string
  size?: number
  onClick?: (e: React.SyntheticEvent) => void
}

export default function Icon({ iconName, size, onClick }: Props) {
  const iconClasses = classNames({
    [styles[`md-${size}`]]: true,
  })

  return <img className={iconClasses} src={srcMap[iconName]} alt={iconName} onClick={onClick} />
}

Icon.defaultProps = {
  backgroundColor: 'transparent',
  size: 24,
  color: 'black',
}
