import React, { useState } from 'react'
import styles from './NavBar.module.scss'
import NavBarOption from '../NavBarOption/NavBarOption'
import Logo from '../Logo/Logo'
import Button from '../Button/Button'
import LoginRegisterDialog from '../LoginRegisterDialog/LoginRegisterDialog'
import ProfileMenuCandidate from '../ProfileMenu/ProfileMenuCandidate'
import ProfileMenuEmployer from '../ProfileMenu/ProfileMenuEmployer'
import { useAuth } from '../../context/AuthContext'
import { useNavigate } from 'react-router-dom'
import { useCurrentUser } from '../../hooks/useCurrentUser'

export default function NavBar() {
  const { isAuthenticated } = useAuth()
  const { data: user } = useCurrentUser()
  const [modalOpen, setModalOpen] = useState(false)
  const navigate = useNavigate()

  return (
    <div className={styles.navBarContainer}>
      {modalOpen && <LoginRegisterDialog closeModal={() => setModalOpen(false)} />}

      {!isAuthenticated && (
        <>
          <div className={styles.navBarLeft}>
            <Logo />
            <NavBarOption to='/' text={'Find Jobs'} />
          </div>
          <div className={styles.space}></div>

          <div className={styles.navBarRight}>
            <Button medium basic text={'Login/Register'} onClick={() => setModalOpen(true)} />
          </div>
        </>
      )}

      {isAuthenticated && (
        <>
          <div className={styles.navBarLeft}>
            <Logo />
            <NavBarOption to='/' text={'Find Jobs'} />
            {/* <NavBarOption to='/matches' text={'Matches'} />
            <NavBarOption to='/messages' text={'Messages'} /> */}
            {/* <NavBarOption to='/search' text={"Search"} /> */}
            {/* <NavBarOption to='/about' text={'About'} /> */}
            {/* <NavBarOption to='/explore' text={"Explore"} /> */}
            {/* <NavBarOption to='/profile' text={'Profile'} /> */}
          </div>
          <div className={styles.space}></div>

          <div className={styles.navBarRight}>
            {/* <Icon iconName={'questionMarkHelp'} color={'black'} />
            <Icon iconName={'bellNotif'} color={'black'} /> */}

            {/* {company?.name && <ProfileMenuEmployer />} */}
            {user?.isEmployer ? <ProfileMenuEmployer /> : <ProfileMenuCandidate />}
            {/* <ProfileMenuCandidate />
            <ProfileMenuEmployer /> */}

            {user?.isEmployer && (
              <Button medium secondary text={'Employers/Post Job'} onClick={() => navigate('/emp/post-job')} />
            )}
          </div>
        </>
      )}
    </div>
  )
}
