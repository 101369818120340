import React from 'react'
import styles from './Header.module.scss'
import { useNavigate } from 'react-router-dom'
import SearchForm from '../SearchForm/SearchForm'

interface Props {
  title: string
  secondaryTitle: string
  jobSearchParam: string
  jobLocationParam: string
}

export default function Header({ title, secondaryTitle }: Props) {
  const navigate = useNavigate()

  return (
    <div className={styles.headerContainer}>
      <h1>{title}</h1>
      <h2>{secondaryTitle}</h2>
      <div className={styles.headerSearchContainer}>
        <SearchForm onSearch={(jobTitle, location) => navigate(`/search?title=${jobTitle}&location=${location}`)} />
      </div>
    </div>
  )
}
