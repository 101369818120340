import React from 'react'
import styles from './TextArea.module.scss'
import classNames from 'classnames'

export enum Size {
  Large = 'Large',
  Small = 'Small',
}

export enum Color {
  White = 'WHITE',
  Gray = 'GRAY',
}
interface InputProps {
  value?: string | number | undefined
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
  size: Size
  bgColor: Color
  type?: string
  min?: string
  errors?: string[]
  placeholder?: string
  name?: string
  disabled?: boolean
}

export default function Input({ name, value, onChange, size, bgColor, errors, placeholder, disabled }: InputProps) {
  const inputClasses = classNames({
    [styles.textarea]: true,
    [styles.large]: size == Size.Large,
    [styles.small]: size == Size.Small,
    [styles.gray]: bgColor == Color.Gray,
    [styles.white]: bgColor == Color.White,
    [styles.errorInput]: errors,
  })

  return (
    <div>
      <textarea
        className={inputClasses}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        disabled={disabled}
      />
      {errors &&
        errors.map((error, index) => (
          <p key={index} className={styles.errorMessage}>
            {error}
          </p>
        ))}
    </div>
  )
}

Input.defaultProps = {
  size: Size.Small,
  bgColor: Color.White,
}
