import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useAuth } from '../context/AuthContext'
import { registerVerify } from '../api/api'

export const useSignupVerifyMutation = () => {
  const { authenticate } = useAuth()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: registerVerify,
    onSuccess: ({ accessToken, refreshToken }) => {
      authenticate(accessToken, refreshToken)
      queryClient.invalidateQueries({ queryKey: ['currentUser'] })
    },
  })
}
