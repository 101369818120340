import { useContext } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import AppliedFilters from '../../components/AppliedFilters/AppliedFilters'
import CheckboxGroup from '../../components/CheckboxGroup/CheckboxGroup'
import Dropdown from '../../components/Dropdown/Dropdown'
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage'
import Header from '../../components/Header/Header'
import Icon from '../../components/Icon/Icon'
import JobCard from '../../components/JobCard/JobCard'
import Pagination from '../../components/Pagination/Pagination'
import RadioButtonGroup from '../../components/RadioButtonGroup/RadioButtonGroup'
import RangeSlider from '../../components/RangeSlider/RangeSlider'
import { AppContext, AppContextInterface } from '../../context/AppContext'
import useSearchFilters from '../../hooks/useSearchFilters'
import { useSearchJobs } from '../../hooks/useSearchJobs'
import { Job } from '../../schemas/schemas'
import styles from './SearchPage.module.scss'

export default function SearchPage() {
  const context: AppContextInterface = useContext(AppContext)
  const [searchParams] = useSearchParams()
  const { filters, setFilters } = useSearchFilters()

  const { data: searchResult, isLoading, isError, error } = useSearchJobs()
  const navigate = useNavigate()

  return (
    <div className={styles.searchContainer}>
      <div>
        <Header
          title={'Join us & Explore Thousands of Jobs'}
          secondaryTitle={'Find Jobs, Employment & Career Opportunities'}
          jobSearchParam={'jobSearchParam'}
          jobLocationParam={'jobLocationParam'}
        />
      </div>

      <div className={styles.searchMain}>
        <div className={styles.searchLeft}>
          <div className={styles.searchLeftCategory}>
            <div className={styles.matchMe}>
              <input
                type='checkbox'
                name='matchMe'
                id='matchMe'
                checked={filters.matchMe}
                onChange={e => setFilters({ ...filters, matchMe: e.target.checked })}
              />
              <label htmlFor='matchMe'>Match my resume</label>
            </div>

            <div className={styles.category}>
              <p className={styles.searchLeftCategoryText}>Industry</p>
              <Dropdown
                icon={<Icon iconName='bag' color='gray' backgroundColor='white' size={20} />}
                placeholder='Choose an Industry'
                data={context.state.industries.map(it => ({ id: it.value, value: it.value }))}
                onChange={value => setFilters({ ...filters, industry: value })}
                value={filters.industry}
              />
            </div>

            <div className={styles.category}>
              <p className={styles.searchLeftCategoryText}>Job Type</p>
              <Dropdown
                placeholder='Choose a Job Type'
                data={context.state.jobTypes}
                onChange={value => setFilters({ ...filters, jobType: value })}
                value={filters.jobType}
              />
            </div>

            <div className={styles.category}>
              <p className={styles.searchLeftCategoryText}>Salary</p>
              <RangeSlider
                min={0}
                max={200000}
                onChange={val => setFilters({ ...filters, pay: val })}
                value={filters.pay || [40000, 200000]}
              />
            </div>

            <div className={styles.category}>
              <p className={styles.searchLeftCategoryText}>Date posted</p>
              <RadioButtonGroup
                options={context.state.postedOptions}
                onChange={(val: string) => setFilters({ ...filters, posted: val })}
                value={filters.posted}
              />
            </div>

            <div className={styles.category}>
              <p className={styles.searchLeftCategoryText}>Experience Level</p>
              <CheckboxGroup
                options={context.state.experienceOptions}
                onChange={val => setFilters({ ...filters, experience: val })}
                value={filters.experience || []}
              />
            </div>
          </div>
        </div>

        <div className={styles.searchRest}>
          <div className={styles.jobApplFilters}>
            <p className={styles.jobApplFiltersText}>Applied Filters</p>
            <AppliedFilters />
          </div>
          <div className={styles.resultsNr}>
            <div className={styles.show}>Showing all {searchResult?.total} results</div>
            <Dropdown
              placeholder='Sort (By Default)'
              data={[
                { id: 'default', value: 'Sort (By Default)' },
                { id: 'pay-asc', value: 'Pay-Low to High' },
                { id: 'pay-dsc', value: 'Pay-High to Low' },
              ]}
              onChange={val => setFilters({ ...filters, sortBy: val })}
              value={filters.sortBy}
            />
          </div>
          <div className={styles.jobContainer}>
            {isLoading && <div>Loading...</div>}
            {isError && <ErrorMessage error={error} />}
            {searchResult?.data?.map((job: Job) => (
              <JobCard key={job.id} job={job} onClick={() => navigate(`/job/${job.id}?${searchParams.toString()}`)} />
            ))}
          </div>
          <Pagination
            onPageChange={pageNr => setFilters({ ...filters, page: pageNr })}
            totalCount={searchResult?.total || 0}
            pageSize={7}
            page={filters.page}
          />
        </div>
      </div>
    </div>
  )
}
