import React, { useState } from 'react'
import styles from './CandidatesList.module.scss'
import Icon from '../Icon/Icon'
import userPic from '../../assets/user_candidate_Profile1.png'
import { CandidateStatusText } from '../../pages/EmployerAllApplicants/EmployerAllApplicants'
import Button from '../Button/Button'
import { useApplicationStatusMutation } from '../../hooks/useApplicationStatusMutation'
import InfoApplicationPanel from '../InfoApplicationPanel/InfoApplicationPanel'
import { CandidateStatus, JobApplicationWithCandidate } from '../../schemas/schemas'
import Dialog from 'rc-dialog'
import z, { ZodError } from 'zod'
import axios from 'axios'
import { Errors } from '../../pages/ProfilePage/ManageEducations'

interface CandidatesListProps {
  title: string
  applications: JobApplicationWithCandidate[]
}

const InviteForInterviewSchema = z.object({
  link: z.string().url(),
})
type InviteForInterview = z.infer<typeof InviteForInterviewSchema>

const initialDisplayCount = 6

const CandidatesList: React.FC<CandidatesListProps> = ({ title, applications }) => {
  const [selectedStatus, setSelectedStatus] = useState<CandidateStatus>(CandidateStatus.New)
  const [loadMore, setLoadMore] = useState(false)
  const applicationStatusMutation = useApplicationStatusMutation()
  const [isOpenInfoApplicationPanel, setIsOpenInfoApplicationPanel] = useState<number | null>(null)
  const [isOpenScheduleDialogWindow, setIsOpenScheduleDialogWindow] = useState(false)
  const [interviewLink, setInterviewLink] = useState('')
  const [errors, setErrors] = useState<Partial<Record<keyof InviteForInterview, string[]>>>({})

  const statusCounts = applications.reduce((acc, { status }) => {
    acc[status] = (acc[status] || 0) + 1
    return acc
  }, {} as Record<CandidateStatus, number>)

  const handleStatusChange = async (applicationId: number, newStatus: CandidateStatus) => {
    applicationStatusMutation.mutate({ applicationId, newStatus })
  }

  const handleLoadMore = () => {
    setLoadMore(!loadMore)
  }

  const filteredApplications = applications
    .filter(app => app.status == selectedStatus)
    .slice(0, loadMore ? applications.length : initialDisplayCount)

  const inviteToInterview = async (id: number) => {
    try {
      const form = {
        link: interviewLink,
      }
      InviteForInterviewSchema.parse(form)
      handleStatusChange(id, CandidateStatus.Interview)
    } catch (error) {
      if (error instanceof ZodError) {
        setErrors(error.flatten().fieldErrors)
      } else if (axios.isAxiosError(error)) {
        console.log('Network error:', error.message)
      } else {
        console.error('Unexpected error:', error)
      }
    }
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.top}>
          <h2>{title}</h2>

          <div className={styles.tabs}>
            {Object.values(CandidateStatus).map(status => {
              const tabClass = `tab-${status.toLowerCase().replace(/\s+/g, '-')}`
              const badgeClass = `badge-${status.toLowerCase().replace(/\s+/g, '-')}`
              return (
                <button
                  key={status}
                  className={`${styles.tab} ${styles[tabClass]} ${selectedStatus === status ? styles.active : ''}`}
                  onClick={() => setSelectedStatus(status)}
                >
                  <span className={styles.tabsText}>{CandidateStatusText[status]}:</span>
                  <span className={`${styles.countBadge} ${styles[badgeClass]}`}>{statusCounts[status] || 0}</span>
                </button>
              )
            })}
          </div>
        </div>

        <div className={styles.candidateList}>
          {filteredApplications.map(app => (
            <div key={app.id} className={styles.candidateCard}>
              <img src={app?.candidate?.avatar || userPic} alt={app?.candidate?.firstName} className={styles.avatar} />
              <div className={styles.info}>
                <h3>
                  {app?.candidate?.firstName} {app?.candidate?.lastName}
                </h3>
                <div className={styles.type}>
                  <p className={styles.role}>{app?.candidate?.position}</p>
                  {/* <Badge
                    text={app.candidate.jobType}
                    type={app.candidate.jobType == 'Full Time' ? BadgeType.Blue : BadgeType.Yellow}
                  /> */}
                </div>

                <div className={styles.location}>
                  <Icon iconName='location' />
                  <p>{app?.candidate?.location}</p>
                </div>
              </div>
              <div className={styles.viewDetailsButtonImage} onClick={() => setIsOpenInfoApplicationPanel(app.id)}>
                {isOpenInfoApplicationPanel && isOpenInfoApplicationPanel == app.id && (
                  <InfoApplicationPanel
                    isOpen={true}
                    onClose={() => setIsOpenInfoApplicationPanel(null)}
                    application={app}
                  />
                )}
                {!isOpenInfoApplicationPanel && <span className={styles.tooltipText}>View application</span>}
                {!isOpenInfoApplicationPanel && (
                  <div className={styles.actionButton}>
                    <Icon iconName={'viewDetails'} size={40} />
                  </div>
                )}
              </div>

              {selectedStatus == CandidateStatus.New && (
                <div className={styles.actions}>
                  <button
                    className={styles.buttonGreen}
                    onClick={() => handleStatusChange(app.id, CandidateStatus.Accepted)}
                  >
                    Accept
                  </button>
                  <button
                    className={styles.buttonRed}
                    onClick={() => handleStatusChange(app.id, CandidateStatus.Rejected)}
                  >
                    Reject
                  </button>
                </div>
              )}
              {selectedStatus == CandidateStatus.Rejected && (
                <div className={styles.actions}>
                  <button
                    className={styles.buttonGreen}
                    onClick={() => handleStatusChange(app.id, CandidateStatus.Accepted)}
                  >
                    Accept
                  </button>
                </div>
              )}
              {selectedStatus == CandidateStatus.Accepted && (
                <div className={styles.actions}>
                  <button className={styles.buttonInvite} onClick={() => setIsOpenScheduleDialogWindow(true)}>
                    Schedule
                  </button>
                  {isOpenScheduleDialogWindow && (
                    <Dialog
                      title='Invite to interview'
                      onClose={() => setIsOpenScheduleDialogWindow(false)}
                      visible
                      className={styles.center}
                      maskAnimation='fade'
                    >
                      <div className={styles.dialog}>
                        <p className={styles.title}>
                          By clicking the Confirm button, an email with the invitation will be sent to the candidate.
                        </p>
                        <label className={styles.title}>
                          Link <span>(Please enter your calendar invite for the interview)</span>
                        </label>
                        <input
                          className={styles.link}
                          value={interviewLink}
                          onChange={e => setInterviewLink(e.target.value)}
                        />
                        <Errors data={errors?.link} />
                        <br />
                        <Button text='Confirm' medium primary onClick={() => inviteToInterview(app.id)} />
                      </div>
                    </Dialog>
                  )}

                  <button
                    className={styles.buttonRed}
                    onClick={() => handleStatusChange(app.id, CandidateStatus.Rejected)}
                  >
                    Reject
                  </button>
                </div>
              )}
              {selectedStatus == CandidateStatus.Interview && (
                <div className={styles.actions}>
                  <button
                    className={styles.buttonHire}
                    onClick={() => handleStatusChange(app.id, CandidateStatus.Hired)}
                  >
                    Hire
                  </button>

                  <button
                    className={styles.buttonRed}
                    onClick={() => handleStatusChange(app.id, CandidateStatus.Rejected)}
                  >
                    Reject
                  </button>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>

      {applications.length > initialDisplayCount && (
        <div className={styles.loadMoreContainer}>
          <Button small primary text={loadMore ? 'Hide' : 'Load More'} onClick={handleLoadMore} />
        </div>
      )}
    </>
  )
}

export default CandidatesList
