import React from 'react'
import styles from './DetailsHeader.module.scss'
import Button from '../Button/Button'
import Badge, { BadgeType } from '../Badge/Badge'
import IconWithText from '../IconWithText/IconWithText'
import { JobWithCompany } from '../../schemas/schemas'
import { formatDate } from '../../utils/date'
import { JobApply } from '../../pages/JobDetailsPage/JobDetailsPage'

interface Props {
  job: JobWithCompany
  jobApply: JobApply
  setJobApply: React.Dispatch<React.SetStateAction<JobApply>>
}

export default function DetailsHeader({ job, jobApply, setJobApply }: Props) {
  const pay = `$${job.payFrom?.toLocaleString('en-US')}-$${job.payTo?.toLocaleString('en-US')}`
  const posted = formatDate(job.createdAt)

  const handleClick = () => {
    if (jobApply.step == 0) setJobApply(prev => ({ ...prev, step: 1 }))
    else setJobApply(prev => ({ ...prev, step: 0 }))
  }

  const applicationStep = jobApply.step

  return (
    <div className={styles.headerContainer}>
      <div className={styles.main}>
        <div className={styles.logo}>
          <img className={styles.jobCardIcon} src={job?.company?.logo} alt='sample_icon' />
        </div>
        <div className={styles.jobCardTextContent}>
          <div className={styles.jobCardTextTitle}>
            <div className={styles.title}>{job.title}</div>
            {job.jobType && (
              <Badge text={job.jobType} type={job.jobType == 'Full Time' ? BadgeType.Blue : BadgeType.Yellow} />
            )}
            <div className={styles.empty} />
          </div>

          <div className={styles.jobCardTextCompanyName}>{job?.company?.name}</div>
          <div className={styles.jobLocationPay}>
            {job.location && <IconWithText text={job.location} iconName={'location'} size={18} />}
            <IconWithText text={`${pay} / year`} iconName={'pay'} size={20} />
            <IconWithText text={posted} iconName={'clock'} size={17} />
          </div>
        </div>
      </div>
      <div className={styles.right}>
        <div className={styles.application}>
          Application ends: <span className={styles.red}> {formatDate(job.deadline)}</span>
        </div>
        <div className={styles.action}>
          <Button text={applicationStep == 0 ? 'Apply Now' : `Cancel`} primary medium onClick={handleClick} />
          {/* <Button text={applicationStep == 0 ? 'Apply Now' : 'Review Company'} primary medium onClick={handleClick} /> */}
          {/* <div className={isBookmarked ? styles.backgroundBookmarkBlue : styles.backgroundBookmarkGrey}>
            <Icon
              iconName={isBookmarked ? 'bookmarkWhite' : 'bookmark'}
              color={'blue'}
              backgroundColor={isBookmarked ? 'white' : 'gray'}
              size={20}
              onClick={e => handleBookmarkOnClick(e)}
            />
          </div> */}
        </div>
      </div>
    </div>
  )
}
