import { useQuery } from '@tanstack/react-query'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getApplicationsByCandidateId } from '../../api/api'
import userPic from '../../assets/user_candidate_Profile1.png'
import Button from '../../components/Button/Button'
import Icon from '../../components/Icon/Icon'
import { useCurrentUser } from '../../hooks/useCurrentUser'
import { CandidateStatus } from '../../schemas/schemas'
import { formatDate } from '../../utils/date'
import styles from './CandidateApplications.module.scss'
// import Badge, { BadgeType } from '../Badge/Badge.tsx'

const CandidateApplications = () => {
  const navigate = useNavigate()
  const [selectedStatus, setSelectedStatus] = useState<CandidateStatus>(CandidateStatus.New)
  const { data: user } = useCurrentUser()
  const [loadMore, setLoadMore] = useState(false)
  const { data: applications } = useQuery({
    queryKey: ['candidateApplications'],
    queryFn: () => {
      if (!user) {
        throw new Error('user is required')
      }
      return getApplicationsByCandidateId(user.id)
    },
    enabled: user !== null,
  })
  const initialDisplayCount = 6

  const totalApplied = applications?.filter(app => app.status === CandidateStatus.New).length || 0
  const totalInvited = applications?.filter(app => app.status === CandidateStatus.Interview).length || 0
  const totalHired = applications?.filter(app => app.status === CandidateStatus.Hired).length || 0

  const handleLoadMore = () => {
    setLoadMore(!loadMore)
  }

  const filteredApplications = (applications || []).filter(app => app.status === selectedStatus)

  const slicedApplications = filteredApplications.slice(0, loadMore ? applications?.length : initialDisplayCount)
  return (
    <div>
      <div className={styles.top}>
        <img className={styles.profilePicture} src={user?.avatar || userPic} width={100} height={100} />
        <div className={styles.profileDetails}>
          <div className={styles.name}>
            {user?.firstName} {user?.lastName}
            <div className={styles.location}>
              <Icon iconName={'arrowOrange'} size={12} />
              <span>{user?.location}</span>
              <div>{user?.title}</div>
            </div>
          </div>
          {/* {!user?.resume_link && <Button primary medium text='Upload Resume' />} */}
        </div>
      </div>

      <div className={styles.container}>
        <h1 className={styles.title}>My Applied Jobs</h1>
        <p className={styles.explain}>Here you can see all you applied vacant position for different types of jobs</p>
        <div className={styles.topFilter}>
          <div className={styles.tabs}>
            <div className={styles.filter}>
              <button
                className={`${styles.tab} ${selectedStatus === CandidateStatus.New ? styles.active : ''}`}
                onClick={() => setSelectedStatus(CandidateStatus.New)}
              >
                <span className={styles.tabsText}>Applied(s):</span> {totalApplied}
              </button>
              <button
                className={`${styles.tab} ${selectedStatus === CandidateStatus.Interview ? styles.active : ''}`}
                onClick={() => setSelectedStatus(CandidateStatus.Interview)}
              >
                <span className={styles.tabsText}>Invited(s): {totalInvited}</span>
              </button>
              <button
                className={`${styles.tab} ${selectedStatus === CandidateStatus.Hired ? styles.active : ''}`}
                onClick={() => setSelectedStatus(CandidateStatus.Hired)}
              >
                <span className={styles.tabsText}>Hired: {totalHired}</span>
              </button>
            </div>
          </div>
        </div>
        <div className={styles.jobList}>
          {slicedApplications?.map(app => (
            <div className={styles.jobCard} key={app.id} onClick={() => navigate(`/job/${app.job?.id}`)}>
              <div className={styles.logo}>
                <img src={app.job?.company?.logo} alt={`${app.job?.company?.name} logo`} />{' '}
              </div>
              <div className={styles.details}>
                <h2>{app.job?.title}</h2>
                {/* <Badge
                  text={app.job?.jobType}
                  type={app.job?.jobType == 'Full Time' ? BadgeType.Blue : BadgeType.Yellow}
                /> */}
                <p>{app.job?.company?.name}</p>
                <div className={styles.locationPay}>
                  <p>
                    <Icon iconName='location' size={20} />
                    <span>{app.job?.location}</span>
                  </p>
                  <p className={styles.locationPayLine}>
                    <Icon iconName='pay' size={20} />
                    <span>${app.job?.payFrom?.toLocaleString('en-US')}</span>
                  </p>
                </div>
              </div>
              <div className={styles.actions}>
                <p>Applied on: {formatDate(app.createdAt)}</p>
                {/* <Link
                  className={`${styles.actionButton} ${styles.viewDetailsButton}`}
                  to={`/applications/${app.job?.id}`}
                >
                  <Icon iconName={'viewDetailsBlue'} />
                </Link>
                <button
                  onClick={() => handleDeleteJob(app.job.id)}
                  className={`${styles.actionButton} ${styles.trashButton}`}
                >
                  {' '}
                  <Icon iconName={'trash'} />{' '}
                </button> */}
              </div>
            </div>
          ))}
        </div>
        {filteredApplications.length > initialDisplayCount && (
          <div className={styles.loadMoreContainer}>
            <Button small primary text={loadMore ? 'Hide' : 'Load More'} onClick={handleLoadMore} />
          </div>
        )}
      </div>
    </div>
  )
}

export default CandidateApplications
