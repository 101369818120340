import { useMutation } from '@tanstack/react-query'
import { updateCompany } from '../api/api'

export const useCompanyUpdateMutation = () => {
  return useMutation({
    mutationFn: updateCompany,
    onSuccess: () => {
      // queryClient.invalidateQueries({ queryKey: ['currentCompany'] })
    },
  })
}
