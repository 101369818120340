import React, { useState } from 'react'
import Dialog from 'rc-dialog'
import styles from './LoginRegVerify.module.scss'

interface LoginRegVerifyProps {
  email: string | undefined
  onVerify: (code: string) => void
  onRequestResend: () => Promise<void>
  onClose: () => void
  verificationError?: string
}

const LoginRegVerify: React.FC<LoginRegVerifyProps> = ({
  email,
  onVerify,
  onRequestResend,
  onClose,
  verificationError,
}) => {
  const [codeInputs, setCodeInputs] = useState(['', '', '', ''])
  const [error, setError] = useState('')
  const [message, setMessage] = useState('')
  const [showInput, setShowInput] = useState(true)

  const handleChange = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const newCodeInputs = [...codeInputs]
    newCodeInputs[index] = event.target.value
    setCodeInputs(newCodeInputs)

    if (event.target.value && index < codeInputs.length - 1) {
      const nextSibling = document.querySelector(`input[name="code-${index + 1}"]`) as HTMLInputElement
      if (nextSibling) {
        nextSibling.focus()
      }
    }
  }

  const handleKeyDown = (index: number) => (event: React.KeyboardEvent<HTMLInputElement>) => {
    // Focus the previous input on Backspace when the current input is empty
    if (event.key === 'Backspace' && !event.currentTarget.value && index > 0) {
      const previousSibling = document.querySelector(`input[name="code-${index - 1}"]`) as HTMLInputElement
      if (previousSibling) {
        previousSibling.focus()
      }
    }
  }

  const handleVerifyClick = () => {
    const code = codeInputs.join('')
    if (code.length === codeInputs.length) {
      setError('') // Clear any previous errors
      onVerify(code)
    } else {
      setError('Please enter the complete code.')
    }
  }

  const handleRequestResend = async () => {
    try {
      await onRequestResend()
      setMessage('Verification code resent! Please enter the verification code that you received.')
      setShowInput(false) // Temporarily hide input to show the message
    } catch (err) {
      setMessage('Failed to resend verification code. Please try again.')
    }

    // Clear the message after 5 seconds and show the input field again
    setTimeout(() => {
      setMessage('')
      setShowInput(true)
    }, 1000)
  }
  return (
    <Dialog title='Verification' onClose={onClose} visible className={styles.center} maskAnimation='fade'>
      <div className={styles.verificationContainer}>
        <h2>Enter Verification Code</h2>
        <p className={styles.text}>
          We've sent a code to <span>{email || 'your email address'}</span>.
        </p>
        <div className={styles.codeInputs}>
          {message}
          {showInput &&
            codeInputs.map((value, index) => (
              <input
                key={index}
                name={`code-${index}`}
                type='text'
                maxLength={1}
                value={value}
                onChange={handleChange(index)}
                onKeyDown={handleKeyDown(index)}
                autoFocus={index === 0}
                pattern='[0-9]*'
                inputMode='numeric'
              />
            ))}
        </div>
        {error && <p className={styles.error}>{error}</p>}
        {verificationError && <p className={styles.error}>{verificationError}</p>}
        <p className={styles.resend}>
          Didn't receive the code? <span onClick={handleRequestResend}>Click to resend</span>.
        </p>
        <div className={styles.btnContainer}>
          <button className={styles.verifyBtn} onClick={handleVerifyClick}>
            Verify
          </button>
        </div>
      </div>
    </Dialog>
  )
}

export default LoginRegVerify
