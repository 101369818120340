import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import Dialog from 'rc-dialog'
import React, { ChangeEvent, useState } from 'react'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { z } from 'zod'
import { getCurrentUser, registerResendCode } from '../../api/api'
import imagePDF from '../../assets/PDFdoc.png'
import cancel from '../../assets/cancel.png'
import resumeTemplate from '../../assets/resumeTemplate.png'
import { Screen } from '../../components/LoginRegisterDialog/LoginRegisterDialog'
import { useAuth } from '../../context/AuthContext'
import { useCurrentUser } from '../../hooks/useCurrentUser'
import { useCurrentUserMutationV2 } from '../../hooks/useCurrentUserMutationV2'
import { useLoginMutation } from '../../hooks/useLoginMutation'
import { useRegistrationMutation } from '../../hooks/useRegistrationMutation'
import { useSignupVerifyMutation } from '../../hooks/useSignupVerifyMutation'
import { useSubmitApplicationMutation } from '../../hooks/useSubmitApplicationMutation'
import { JobApply } from '../../pages/JobDetailsPage/JobDetailsPage'
import {
  LoginRequest,
  LoginRequestErrors,
  RegisterRequest,
  RegisterRequestErrors,
  UserType,
  loginSchema,
  registerSchema,
} from '../../schemas/schemas'
import Button from '../Button/Button'
import { ContactUsForm } from '../ContactUsForm'
import Icon from '../Icon/Icon'
import Input, { Color } from '../Input/Input'
import LoginRegVerify from '../LoginRegVerify/LoginRegVerify'
import RelevantJobExperience from '../RelevantJobExperience/RelevantJobExperience'
import Spinner from '../Spinner/Spinner'
import SuccessComponent from '../SuccessComponent/SuccessComponent'
import styles from './JobApplication.module.scss'

interface Props {
  jobApply: JobApply
  setJobApply: React.Dispatch<React.SetStateAction<JobApply>>
}

const JobApplication = ({ jobApply, setJobApply }: Props) => {
  const { isAuthenticated } = useAuth()
  const { data: currentUser } = useCurrentUser()
  const currentUserMutation = useCurrentUserMutationV2()
  const loginMutation = useLoginMutation()
  const registrationMutation = useRegistrationMutation()
  const submitApplicationMutation = useSubmitApplicationMutation()
  const registerVerifyMutation = useSignupVerifyMutation()
  const registerResendCodeMutation = useMutation({
    mutationFn: registerResendCode,
  })

  const [regData, setRegData] = useState<RegisterRequest>({
    userType: UserType.CANDIDATE,
    email: '',
    password: '',
    confirmPassword: '',
  })
  const [regErrors, setRegErrors] = useState<RegisterRequestErrors>({})
  const [errors, setErrors] = useState<LoginRequestErrors>({})
  const [verificationError, setVerificationError] = useState('')

  const handleChangeReg = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setRegData({ ...regData, [name]: value })
  }

  const { firstName, lastName, location, phone, email, step: contextStep, jobItemsList, file } = jobApply
  const setFile = (val: File) => setJobApply(prev => ({ ...prev, file: val }))
  const setStep = (val: number) => setJobApply({ ...jobApply, step: val })
  const setFirstName = (val: string) => setJobApply({ ...jobApply, firstName: val })
  const setLastName = (val: string) => setJobApply({ ...jobApply, lastName: val })
  const setLocation = (val: string) => setJobApply({ ...jobApply, location: val })
  const setPhone = (val: string) => setJobApply({ ...jobApply, phone: val })
  const setEmail = (val: string) => setJobApply(prev => ({ ...prev, email: val }))

  const [screen, setScreen] = useState<Screen>()

  const [showTermsModal, setShowTermsModal] = useState(false)
  const [showContactUsModal, setShowContactUsModal] = useState(false)

  const [firstNameError, setFirstNameError] = useState('')
  const [lastNameError, setLastNameError] = useState('')

  // Function to toggle the modal
  const toggleTermsModal = () => {
    setShowTermsModal(!showTermsModal)
  }

  const toggleContactUsModal = () => {
    setShowContactUsModal(!showContactUsModal)
  }

  const [formData, setFormData] = useState<LoginRequest>({ email: '', password: '', rememberMe: false })

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.type === 'file') {
      // Handle file inputs
      if (e.target.files && e.target.files[0]) {
        const selectedFile = e.target.files[0]
        setFile(selectedFile)
      }
    } else {
      // Handle text inputs
      const { name, value } = e.target
      setFormData(prevState => ({
        ...prevState,
        [name]: value,
      }))
    }
  }

  const handleBack = () => {
    setStep(contextStep - 1)
  }

  const submitApplication = async (nextScreen: Screen) => {
    try {
      // TODO: fix it we should not send user_id (at this point we always have user)
      const user = await getCurrentUser()

      await submitApplicationMutation.mutateAsync({ jobApply, user })
      setScreen(nextScreen)
    } catch (error) {
      console.error(error)
    }
  }

  const handleLogIn = (e: React.SyntheticEvent) => {
    e.preventDefault()
    try {
      loginSchema.parse(formData)
      loginMutation.mutate(formData, {
        onSuccess: async () => {
          await submitApplication(Screen.SUCCESS_LOGIN)
        },
        onError: (error: Error) => {
          if (error instanceof AxiosError) {
            const errMessage = String(error.response?.data.detail) || error.message
            if (errMessage.includes('password')) {
              setErrors({ password: errMessage })
            } else {
              setErrors({ email: errMessage })
            }
          }
        },
      })
    } catch (error) {
      if (error instanceof z.ZodError) {
        const newErrors: LoginRequestErrors = {}
        error.errors.forEach(err => {
          if (err.path[0]) newErrors[err.path[0] as keyof LoginRequest] = err.message
        })
        setErrors(newErrors)
      }
    }
  }

  const handleSignUp = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    try {
      const mergedRegData: RegisterRequest = {
        ...regData,
        firstName: jobApply.firstName,
        lastName: jobApply.lastName,
        location: jobApply.location,
        phone: jobApply.phone,
      }
      registerSchema.parse(mergedRegData)
      await registrationMutation.mutateAsync(mergedRegData)

      setScreen(Screen.FORGOT_PASSWORD_VERIFY)
    } catch (error) {
      if (error instanceof z.ZodError) {
        const newErrors: RegisterRequestErrors = {}
        error.errors.forEach(err => {
          const key = err.path[0] as keyof RegisterRequest
          if (key && !newErrors[key]) {
            newErrors[key] = err.message
          }
        })
        setRegErrors(newErrors)
      }
      if (error instanceof AxiosError) {
        const errMessage = String(error.response?.data.detail?.[0]?.msg) || error.message
        if (errMessage.toLocaleLowerCase().includes('email')) {
          setRegErrors({ email: errMessage })
        } else {
          setRegErrors({ password: errMessage })
        }
      }
    }
  }

  const handleCloseModal = () => {
    setScreen(undefined)
  }

  // Finally, the onVerify function will need to take the verification code entered by the user, check it against some verification service (like an API), and then react accordingly:
  const handleVerifyVerificationCode = async (code: string) => {
    try {
      await registerVerifyMutation.mutateAsync({ email: regData.email, code })
      setVerificationError('') // Clear any existing errors
      await submitApplication(Screen.SIGN_UP_SUCCESS)
    } catch (err) {
      setVerificationError('Incorrect verification code. Please try again.')
    }
  }

  const handleRequestResendVerificationCode = async () => {
    await registerResendCodeMutation.mutateAsync(regData.email)
  }

  function formatPhoneNumber(phoneNumberString: string) {
    const phoneNumber = parsePhoneNumberFromString(phoneNumberString)
    if (!phoneNumber) return phoneNumberString // Return original string if parsing fails
    return phoneNumber.formatInternational()
  }

  const handleSubmitApplication = async () => {
    if (!isAuthenticated) {
      setRegData(prev => ({ ...prev, email: email }))
      setScreen(Screen.SIGN_UP)
    } else {
      await submitApplication(Screen.SIGN_UP_SUCCESS)
    }
  }

  const handleContactInformation = async () => {
    if (!firstName) {
      setFirstNameError('First name is required')
    } else {
      setFirstNameError('')
    }

    if (!lastName) {
      setLastNameError('Last name is required')
    } else {
      setLastNameError('')
    }

    if (firstName && lastName) {
      if (isAuthenticated && currentUser && (!currentUser?.firstName || !currentUser?.lastName)) {
        await currentUserMutation.mutateAsync({
          id: currentUser.id,
          firstName: firstName,
          lastName: lastName,
        })
      }
      setStep(2)
    }
  }

  return (
    <>
      {(loginMutation.isPending || registrationMutation.isPending || submitApplicationMutation.isPending) && (
        <Spinner />
      )}
      {contextStep == 1 && (
        <div className={styles.frame}>
          <div className={styles.progressBar}>
            <div className={styles.progress1}></div>
          </div>
          <div className={styles.contactTitle}>Add your contact information</div>
          <div className={styles.contactDetails}>
            <p>First Name</p>
            <Input
              bgColor={Color.White}
              value={firstName}
              onChange={e => setFirstName(e.target.value)}
              disabled={isAuthenticated && !!currentUser?.firstName}
            />
            {firstNameError && <div className={styles.error}>{firstNameError}</div>}
          </div>
          <div className={styles.contactDetails}>
            <p>Last Name</p>
            <Input
              bgColor={Color.White}
              value={lastName}
              onChange={e => setLastName(e.target.value)}
              disabled={isAuthenticated && !!currentUser?.lastName}
            />
            {lastNameError && <div className={styles.error}>{lastNameError}</div>}
          </div>
          <div className={styles.contactDetails}>
            <p>Email Address</p>
            <Input value={email} onChange={e => setEmail(e.target.value)} />
          </div>
          <div className={styles.contactDetails}>
            <p>
              City/State <span>(Optional)</span>
            </p>
            <Input value={location} onChange={e => setLocation(e.target.value)} />
          </div>
          <div className={styles.contactDetails}>
            <p>Phone number</p>
            <PhoneInput
              inputClass={styles.phoneInputClass}
              buttonClass={styles.phoneButtonClass}
              country={'us'}
              value={phone}
              onChange={value => setPhone(value)}
            />
          </div>
          <div className={styles.btnContainer}>
            <Button text={'Continue'} primary small onClick={() => handleContactInformation()} />
          </div>

          <div className={styles.support}>
            Are you having any issues?{' '}
            <a href='#' onClick={toggleContactUsModal}>
              Tell Us
            </a>
          </div>
        </div>
      )}

      {contextStep == 2 && (
        <div className={styles.frame}>
          <div className={styles.back}>
            <a href='#' onClick={handleBack}>
              <Icon iconName='arrowBack' size={12} /> Back
            </a>
          </div>
          <div className={styles.progressBar}>
            <div className={styles.progress2}></div>
          </div>
          <div className={styles.resume}>
            <p>Add a resume for the employer</p>
            <iframe src={file ? URL.createObjectURL(file) : resumeTemplate} width='100%' height='500px'></iframe>
            <input
              type='file'
              accept='.pdf,.doc,.docx'
              className={styles.upload}
              name='file'
              id='file'
              onChange={handleChange}
            />
            <label htmlFor='file' className={styles.upload_label}>
              <p>
                <Icon iconName='arrowUp' size={15} />
                <span>Upload Resume</span>
              </p>
            </label>

            <p className={styles.allowed}>Allowed file types: Word, PDF</p>
          </div>
          <div className={styles.btnContainer}>
            <Button text={'Continue'} primary small onClick={() => setStep(3)} />
          </div>

          <div className={styles.support}>
            Are you having any issues?{' '}
            <a href='#' onClick={toggleContactUsModal}>
              Tell Us
            </a>
          </div>
        </div>
      )}

      {contextStep == 3 && (
        <div className={styles.frame}>
          <div className={styles.back}>
            <a href='#;' onClick={handleBack}>
              <Icon iconName='arrowBack' size={12} /> Back
            </a>
          </div>
          <div className={styles.progressBar}>
            <div className={styles.progress3}></div>
          </div>

          <div className={styles.resume}>
            <p>Enter jobs that shows relevant experience</p>
            <h2>Relevant Job Experience</h2>
          </div>

          <RelevantJobExperience jobApply={jobApply} setJobApply={setJobApply} />

          <div className={styles.btnContainer}>
            <Button text={'Continue'} primary small onClick={() => setStep(4)} />
          </div>

          <div className={styles.support}>
            Are you having any issues?{' '}
            <a href='#' onClick={toggleContactUsModal}>
              Tell Us
            </a>
          </div>
        </div>
      )}

      {contextStep == 4 && (
        <div className={styles.frame2}>
          <div className={styles.back}>
            <a href='#;' onClick={handleBack}>
              <Icon iconName='arrowBack' size={12} /> Back
            </a>
          </div>
          <div className={styles.progressBar}>
            <div className={styles.progress5}></div>
          </div>
          <div className={styles.resume}>
            <h2>Please review your application</h2>
          </div>
          <div className={styles.contactInf}>
            <div className={styles.header}>
              <p>Contact information</p>
              <a href='#;' onClick={() => setStep(1)}>
                Edit
              </a>
            </div>
            <div className={styles.twoColumns}>
              <div>
                <p className={styles.title}>Full Name</p>
                <p className={styles.content}>
                  {firstName} {lastName}
                </p>
              </div>
              <div>
                <p className={styles.title}>Email Address</p>
                <p className={styles.content}>{email}</p>
              </div>
              <div>
                <p className={styles.title}>Location</p>
                <p className={styles.content}>{location}</p>
              </div>
              <div>
                <p className={styles.title}>Phone number</p>
                <p className={styles.content}>{phone && formatPhoneNumber(phone)}</p>
              </div>
            </div>
          </div>
          <div className={styles.cv}>
            <div className={styles.header}>
              <p>CV/ Resume</p>
              <a href='#;' onClick={() => setStep(2)}>
                Edit
              </a>
            </div>
            <div className={styles.file}>
              <img src={imagePDF} alt='imagePDF' />
              <p>{file?.name}</p>
            </div>
          </div>
          <div className={styles.relExp}>
            <div className={styles.header}>
              <p className={styles.title}>Relevant Experience</p>
              <a href='#;' onClick={() => setStep(3)}>
                Edit
              </a>
            </div>
            <div className={styles.jobs}>
              {jobItemsList.length > 0 &&
                jobItemsList.map((job, index) => (
                  <div key={index}>
                    <p>{job.company}</p>
                    <p className={styles.title}>{job.position}</p>
                  </div>
                ))}
            </div>
          </div>
          <div className={styles.certifications}>
            <div className={styles.header}>
              <p className={styles.title}>Certifications</p>
              {/* <a href='#;' onClick={() => setStep(4)}>
                Edit
              </a> */}
            </div>
          </div>
          <div className={styles.terms}>
            <div>
              <p>By pressing apply:</p>
              <p>
                <b>1)</b> You agree to our{' '}
                <span onClick={toggleTermsModal} className={styles.termsLink}>
                  Terms, Cookie & Privacy Policies
                </span>
              </p>
              <p>
                {/* Modal Terms and Conditions*/}
                {showTermsModal && (
                  <div className={styles.modalOverlay}>
                    <div className={styles.modalContent}>
                      <span className={styles.modalCloseButton} onClick={toggleTermsModal}>
                        <img src={cancel} alt='close' />
                      </span>
                      <br />
                      <b className={styles.bigTitle}>Terms of Use </b>
                      <br />
                      <p className={styles.title}>1. Introduction</p>
                      <p className={styles.content}>
                        Welcome to Pavedit - our Job Application Platform, a place where job seekers can discover and
                        apply for job opportunities posted by potential employers. By accessing our platform, you agree
                        to be bound by these Terms of Use, our Privacy Policy, and Cookie Policy. If you do not agree
                        with any part of these terms, you must not use our platform.
                      </p>
                      <p className={styles.title}>2. Use of Our Platform</p>
                      <p className={styles.content}>
                        Our platform is designed to connect job seekers with potential employers. You may use our
                        platform only for lawful purposes and in accordance with these Terms. You agree not to use the
                        platform: In any way that violates any applicable federal, state, local, or international law or
                        regulation. For the purpose of exploiting, harming, or attempting to exploit or harm others in
                        any way. To send, knowingly receive, upload, download, use, or re-use any material that does not
                        comply with the content standards set out in these Terms.
                      </p>
                      <p className={styles.title}>3. Intellectual Property Rights</p>
                      <p className={styles.content}>
                        The content on our platform, including but not limited to text, graphics, images, software, and
                        trademarks, is owned by us or our licensors and is protected by copyright and trademark laws.
                        You are granted a limited license to access and use the platform for your personal,
                        non-commercial use.
                      </p>
                      <p className={styles.title}>4. User Contributions </p>
                      <p className={styles.content}>
                        Users may post content, including personal information, resumes, and job postings. By posting
                        content on our platform, you grant us a non-exclusive, royalty-free, perpetual, and worldwide
                        license to use, reproduce, modify, perform, display, distribute, and otherwise disclose to third
                        parties any such material.
                      </p>
                      <p className={styles.title}>5. Account Security</p>
                      <p className={styles.content}>
                        You are responsible for maintaining the confidentiality of your account information, including
                        your password, and for all activity that occurs under your account. You must notify us
                        immediately upon becoming aware of any breach of security or unauthorized use of your account.
                      </p>
                      <p className={styles.title}>6. Modifications to the Platform</p>
                      <p className={styles.content}>
                        We reserve the right to modify or discontinue, temporarily or permanently, the platform or any
                        service to which it connects, with or without notice and without liability to you.
                      </p>
                      <p className={styles.title}>7. Termination </p>
                      <p className={styles.content}>
                        We may terminate or suspend your access to our platform immediately, without prior notice or
                        liability, for any reason whatsoever, including, without limitation, if you breach these Terms.
                      </p>
                      <br />
                      <b className={styles.bigTitle}>Cookie Policy </b>
                      <br />
                      <p className={styles.title}>1. Use of Cookies</p>
                      <p className={styles.content}>
                        Our platform uses cookies to improve your browsing experience, personalize content and ads,
                        provide social media features, and analyze our traffic. By using our platform, you consent to
                        our use of cookies.
                      </p>
                      <p className={styles.title}>2. Types of Cookies Used</p>
                      <p className={styles.content}>
                        Essential Cookies: Necessary for the operation of our platform. They include, for example,
                        cookies that enable you to log into secure areas. Analytical/Performance Cookies: Allow us to
                        recognize and count the number of visitors and to see how visitors move around our platform.
                        Functionality Cookies: Used to recognize you when you return to our platform. Targeting Cookies:
                        Record your visit to our platform, the pages you have visited, and the links you have followed.
                      </p>
                      <p className={styles.title}>3. Managing Cookies</p>
                      <p className={styles.content}>
                        You can manage cookies through your browser settings at any time. However, disabling cookies may
                        affect the functionality of our platform.
                      </p>
                      <br />
                      <b className={styles.bigTitle}>Privacy Policy</b>
                      <p className={styles.title}>1. Information We Collect</p>
                      <p className={styles.content}>
                        We may collect and process personal data about you, including but not limited to your name,
                        address, email address, phone number, education, work history, and information contained in your
                        resume.
                      </p>
                      <p className={styles.title}>2. How We Use Your Information</p>
                      <p className={styles.content}>
                        We use your information to: Provide our platform and services to you. Communicate with you about
                        your account or services you have requested. Enhance our platform and develop new services.
                        Comply with legal obligations.
                      </p>
                      <p className={styles.title}>3. Sharing Your Information</p>
                      <p className={styles.content}>
                        We may share your information with potential employers, service providers, and other third
                        parties if necessary to provide our services to you or for legal reasons.
                      </p>
                      <p className={styles.title}>4. Data Security</p>
                      <p className={styles.content}>
                        We implement appropriate security measures to protect against unauthorized access, alteration,
                        disclosure, or destruction of your personal information.
                      </p>
                      <p className={styles.title}>5. Your Rights</p>
                      <p className={styles.content}>
                        You have the right to access, correct, or delete your personal data stored on our platform. You
                        can exercise these rights by contacting us.
                      </p>
                      <p className={styles.title}>6. Changes to Our Privacy Policy</p>
                      <p className={styles.content}>
                        We may update our Privacy Policy from time to time. We will notify you of any changes by posting
                        the new Privacy Policy on our platform. Contact Us If you have any questions about these Terms,
                        the Practices of this platform, or your dealings with this platform, please contact us.
                      </p>
                    </div>
                  </div>
                )}
                <b>2)</b> You consent to your application being transmitted to the Employer, & processed & analysed in
                accordance with its & Pavedit’s terms & privacy policies
              </p>
              <p>
                <b>3)</b> You acknowledge that when you apply to jobs outside your country it may involve you sending
                your personal data to countries with lower levels of data protection.
              </p>
              <p>
                By submitting your phone number, you confirm that you are the subscriber and primary owner and you
                consent to receiving calls and text messages from Pavedit and employers.
              </p>
            </div>
          </div>

          <div className={styles.btnContainer}>
            <button className={styles.submitButton} onClick={handleSubmitApplication}>
              Submit your application
            </button>
          </div>

          <div className={styles.support}>
            Are you having any issues?{' '}
            <a href='#' onClick={toggleContactUsModal}>
              Tell Us
            </a>
          </div>
        </div>
      )}

      {/* if you do not have an account yet Submit your application-> will trigger creation of a new account */}
      {screen == Screen.SIGN_UP && (
        <Dialog
          title='Register to Pavedit'
          onClose={handleCloseModal}
          visible
          className={styles.center}
          maskAnimation='fade'
        >
          <p className={styles.infoReg}>
            In order to submit your application you have to have an account on PavedIt. To create an account please pick
            and enter a password below. If you do have an account already please{' '}
            <span className={styles.logInLink} onClick={() => setScreen(Screen.LOGIN)}>
              Log in
            </span>
          </p>
          <form onSubmit={handleSignUp}>
            <div className={styles.auto}>
              <p className={styles.titleName}>Username*</p>
              <Input bgColor={Color.Gray} name='email' value={regData.email} onChange={handleChangeReg} />
              {regErrors.email && <div className={styles.error}>{regErrors.email}</div>}
            </div>

            <div className={styles.auto}>
              <p className={styles.titleName}>Password*</p>
              <Input
                bgColor={Color.Gray}
                type='password'
                name='password'
                value={regData.password}
                onChange={handleChangeReg}
              />
              {regErrors.password && <div className={styles.error}>{regErrors.password}</div>}
            </div>
            <div className={styles.auto}>
              <p className={styles.titleName}>Confirm Password*</p>
              <Input
                bgColor={Color.Gray}
                type='password'
                name='confirmPassword'
                value={regData.confirmPassword}
                onChange={handleChangeReg}
              />
              {regErrors.confirmPassword && <div className={styles.error}>{regErrors.confirmPassword}</div>}
            </div>
            <button type='submit' className={styles.logIn} disabled={registrationMutation.isPending}>
              Sign Up
            </button>
          </form>
        </Dialog>
      )}

      {screen === Screen.FORGOT_PASSWORD_VERIFY && (
        <LoginRegVerify
          email={regData.email || ''}
          onVerify={handleVerifyVerificationCode}
          onRequestResend={handleRequestResendVerificationCode}
          verificationError={verificationError}
          onClose={handleCloseModal}
        />
      )}

      {screen === Screen.SIGN_UP_SUCCESS && (
        <SuccessComponent
          message='Congratulations, your account has been successfully created and your application was successfully submitted.'
          onContinue={handleCloseModal} // TO DO: Maybe transfer user to his all applications(dashboard??)
          onClose={handleCloseModal}
        />
      )}

      {screen === Screen.SUCCESS_LOGIN && (
        <SuccessComponent
          message='Your application was successfully submitted.'
          onContinue={() => setScreen(undefined)}
          onClose={handleCloseModal} // TO DO: Maybe transfer user to his all applications(dashboard??)
        />
      )}

      {screen == Screen.LOGIN && (
        <Dialog
          title='Login to Pavedit'
          onClose={handleCloseModal}
          visible
          className={styles.center}
          maskAnimation='fade'
        >
          <form onSubmit={handleLogIn}>
            <div className={styles.auto}>
              <p className={styles.titleName}>Username*</p>
              <Input bgColor={Color.Gray} name='email' value={formData.email} onChange={handleChange} />
              {errors.email && <div className={styles.error}>{errors.email}</div>}
            </div>
            <div className={styles.auto}>
              <p className={styles.titleName}>Password*</p>
              <Input
                name='password'
                bgColor={Color.Gray}
                type='password'
                value={formData.password}
                onChange={handleChange}
              />
              {errors.password && <div className={styles.error}>{errors.password}</div>}
            </div>
            <div className={styles.options}>
              <div className={styles.remember}>
                <input
                  className={styles.rememberCheckbox}
                  type='checkbox'
                  id='remember'
                  checked={formData.rememberMe}
                  onChange={e => setFormData({ ...formData, rememberMe: e.target.checked })}
                />
                <label htmlFor='remember'>Remember Me</label>
              </div>
              <span className={styles.forgot} onClick={() => setScreen(Screen.FORGOT_PASSWORD_VERIFY)}>
                Forgot your password?
              </span>
            </div>
            <button type='submit' className={styles.logIn} disabled={loginMutation.isPending}>
              Log In
            </button>
          </form>

          <div className={styles.new_account}>
            <span className={styles.text}>Don't have an account? </span>
            <span onClick={() => setScreen(Screen.SIGN_UP)} className={styles.logInLink}>
              Sign Up
            </span>
          </div>
        </Dialog>
      )}
      {showContactUsModal && (
        <Dialog
          title='Contact Us'
          onClose={toggleContactUsModal}
          visible={showContactUsModal}
          className={styles.contactUsModal}
          maskAnimation='fade'
        >
          <ContactUsForm handleClose={toggleContactUsModal} />
        </Dialog>
      )}
    </>
  )
}
export default JobApplication
