import { Outlet } from 'react-router-dom'
import NavBar from '../../components/NavBar/NavBar'
import ScrollToTop from '../../helpers/ScrollToTop'

export default function RootLayout() {
  return (
    <div className='app'>
      <ScrollToTop />
      <NavBar />
      <Outlet />
    </div>
  )
}
