import { useEffect, useState } from 'react'
import Dialog from 'rc-dialog'
import styles from './EmployerPostNewJob.module.scss'
import Button from '../../components/Button/Button'
import { Link, useParams } from 'react-router-dom'
import Icon from '../../components/Icon/Icon'
import EmployerPostNewJobForm from './EmployerPostNewJobForm'

export enum Screen {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
  SUCCESS = 'SUCCESS',
}

export default function EmployerPostNewJob() {
  const jobId = Number(useParams().jobId)
  const [screen, setScreen] = useState<Screen>(jobId ? Screen.EDIT : Screen.CREATE)

  useEffect(() => {
    setScreen(jobId ? Screen.EDIT : Screen.CREATE)
  }, [jobId])

  const handleCloseDialog = () => {
    setScreen(Screen.CREATE)
  }

  return (
    <>
      <div>
        {screen === Screen.SUCCESS && (
          <Dialog title='Success' visible className={styles.center} maskAnimation='fade' onClose={handleCloseDialog}>
            <div className={styles.success_window}>
              <Icon iconName={'success'} size={100} />
              <p id='message'>Congratulations, your job posting has been successfully created.</p>
              See all your job postings <Link to={`/emp/manage-jobs`}>here</Link>.
              <Button primary medium text='Post a New Job' onClick={() => setScreen(Screen.CREATE)} />
            </div>
          </Dialog>
        )}
      </div>
      {screen === Screen.CREATE && <EmployerPostNewJobForm key='create' setScreen={setScreen} />}
      {screen === Screen.EDIT && <EmployerPostNewJobForm key='edit' setScreen={setScreen} />}
    </>
  )
}
