import React from 'react'
import Icon from '../Icon/Icon'
import styles from './IconWithText.module.scss'

interface Props {
  text: string
  iconName: string
  size: number
}

export default function IconWithText({ text, iconName, size }: Props) {
  return (
    <div className={styles.container}>
      <Icon iconName={iconName} color={'grey'} backgroundColor={'gray'} size={size} />
      <div className={styles.text}>{text}</div>
    </div>
  )
}
