import { useMutation, useQueryClient } from '@tanstack/react-query'
import { deleteEducation } from '../api/api'

export const useDeleteEducationMutation = (userId: number) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: deleteEducation,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['user', userId] })
    },
  })
}
