import Dialog from 'rc-dialog'
import { useEffect, useState } from 'react'
import Button from '../../components/Button/Button'
import { useUser } from '../../hooks/useUser'
import { User, UserSchema } from '../../schemas/schemas'
// import styles from './ProfilePage.module.scss'
import imageCancel from '../../assets/cancel.png'
import { useCurrentUserMutation } from '../../hooks/useCurrentUserMutation'
import styles from './ManagePreferences.module.scss'

//from Manage experiences

interface Props {
  userId: number
  onClose: () => void
}

export default function ManagePreferences({ onClose, userId }: Props) {
  const { data: user } = useUser(userId)
  const [jobTitles, setJobTitles] = useState<string[]>([])
  const [selectedJobTitle, setSelectedJobTitle] = useState<string>('')
  const [jobWorkplaces, setJobWorkplaces] = useState<string[]>([])
  const [selectedJobWorkplace, setSelectedJobWorkplace] = useState<string>()
  const [jobLocations, setJobLocations] = useState<string[]>([])
  const [selectedJobLocation, setSelectedJobLocation] = useState<string>()
  const [jobTypes, setJobTypes] = useState<string[]>([])
  const [selectedJobType, setSelectedJobType] = useState<string>()
  const [skills, setSkills] = useState<string[]>([])
  const [selectedSkill, setSelectedSkill] = useState<string>()
  const currentUserMutation = useCurrentUserMutation(userId)

  const addJobTitle = (e: React.SyntheticEvent) => {
    e.preventDefault()
    e.stopPropagation()
    if (selectedJobTitle) {
      setJobTitles([...jobTitles, selectedJobTitle])
      setSelectedJobTitle('')
    }
  }

  const addJobWorkplace = (e: React.SyntheticEvent) => {
    e.preventDefault()
    e.stopPropagation()
    if (selectedJobWorkplace) {
      setJobWorkplaces([...jobWorkplaces, selectedJobWorkplace])
      setSelectedJobWorkplace('')
    }
  }

  const addJobLocation = (e: React.SyntheticEvent) => {
    e.preventDefault()
    e.stopPropagation()
    if (selectedJobLocation) {
      setJobLocations([...jobLocations, selectedJobLocation])
      setSelectedJobWorkplace('')
    }
  }

  const addJobType = (e: React.SyntheticEvent) => {
    e.preventDefault()
    e.stopPropagation()
    if (selectedJobType) {
      setJobTypes([...jobTypes, selectedJobType])
      setSelectedJobType('')
    }
  }

  const addSkill = (e: React.SyntheticEvent) => {
    e.preventDefault()
    e.stopPropagation()
    if (selectedSkill) {
      setSkills([...skills, selectedSkill])
      setSelectedSkill('')
    }
  }

  useEffect(() => {
    if (user) {
      setJobTitles(user?.preferencesTitles || [])
      setJobWorkplaces(user?.preferencesWorkplaces || [])
      setJobLocations(user?.preferencesLocations || [])
      setJobTypes(user?.preferencesTypes || [])
      setSkills(user?.skills || [])
    }
  }, [user])

  const handleSave = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    try {
      const updatedData: User = {
        id: userId,
        preferencesTitles: jobTitles,
        preferencesWorkplaces: jobWorkplaces,
        preferencesLocations: jobLocations,
        preferencesTypes: jobTypes,
        skills,
      }
      UserSchema.parse(updatedData)
      await currentUserMutation.mutateAsync(updatedData)
      onClose()
    } catch (err) {}
  }

  if (!user) return <span>Loading...</span>

  return (
    <Dialog
      title={'Edit your job preferences and professional skills'}
      onClose={onClose}
      visible={true}
      className={styles.dialogPreferencesModal}
      maskAnimation='fade'
      style={{ top: '20%' }}
    >
      <div className={styles.formLayout}>
        <form onSubmit={addJobTitle}>
          <div>
            <p className={styles.header}>Job tites</p>
            <div className={styles.input}>
              <input type='text' value={selectedJobTitle} onChange={e => setSelectedJobTitle(e.target.value)} />
              <button className={styles.buttonInput}>Add</button>
            </div>

            <div className={styles.selectedJobTitles}>
              {jobTitles?.map((title, index) => (
                <div key={index} className={styles.selected}>
                  <p>{title}</p>
                  <img
                    src={imageCancel}
                    alt='remove'
                    onClick={() => setJobTitles(jobTitles.filter(it => it != title))}
                  />
                </div>
              ))}
            </div>
          </div>
        </form>

        <form onSubmit={addJobWorkplace}>
          <div>
            <p className={styles.header}>Workplaces</p>
            <div className={styles.input}>
              <input type='text' value={selectedJobWorkplace} onChange={e => setSelectedJobWorkplace(e.target.value)} />
              <button className={styles.buttonInput}>Add</button>
            </div>

            <div className={styles.selectedJobTitles}>
              {jobWorkplaces?.map((title, index) => (
                <div key={index} className={styles.selected}>
                  <p>{title}</p>
                  <img
                    src={imageCancel}
                    alt='remove'
                    onClick={() => setJobWorkplaces(jobWorkplaces.filter(it => it != title))}
                  />
                </div>
              ))}
            </div>
          </div>
        </form>

        <form onSubmit={addJobLocation}>
          <div>
            <p className={styles.header}>Locations</p>
            <div className={styles.input}>
              <input type='text' value={selectedJobLocation} onChange={e => setSelectedJobLocation(e.target.value)} />
              <button className={styles.buttonInput}>Add</button>
            </div>

            <div className={styles.selectedJobTitles}>
              {jobLocations?.map((title, index) => (
                <div key={index} className={styles.selected}>
                  <p>{title}</p>
                  <img
                    src={imageCancel}
                    alt='remove'
                    onClick={() => setJobLocations(jobLocations.filter(it => it != title))}
                  />
                </div>
              ))}
            </div>
          </div>
        </form>

        <form onSubmit={addJobType}>
          <div>
            <p className={styles.header}>Job types</p>
            <div className={styles.input}>
              <input type='text' value={selectedJobType} onChange={e => setSelectedJobType(e.target.value)} />
              <button className={styles.buttonInput}>Add</button>
            </div>

            <div className={styles.selectedJobTitles}>
              {jobTypes?.map((title, index) => (
                <div key={index} className={styles.selected}>
                  <p>{title}</p>
                  <img src={imageCancel} alt='remove' onClick={() => setJobTypes(jobTypes.filter(it => it != title))} />
                </div>
              ))}
            </div>
          </div>
        </form>

        <form onSubmit={addSkill}>
          <div>
            <p className={styles.header}>Professional skills</p>
            <div className={styles.input}>
              <input type='text' value={selectedSkill} onChange={e => setSelectedSkill(e.target.value)} />
              <button className={styles.buttonInput}>Add</button>
            </div>

            <div className={styles.selectedJobTitles}>
              {skills?.map((title, index) => (
                <div key={index} className={styles.selected}>
                  <p>{title}</p>
                  <img src={imageCancel} alt='remove' onClick={() => setSkills(skills.filter(it => it != title))} />
                </div>
              ))}
            </div>
          </div>
        </form>

        <div className={styles.footer}>
          <Button primary medium text='Save' onClick={handleSave} />
        </div>
      </div>
    </Dialog>
  )
}
