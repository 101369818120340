import React from 'react'
import { NavLink, Outlet, useNavigate } from 'react-router-dom'
import ScrollToTop from '../../helpers/ScrollToTop'
import styles from './EmployerLayout.module.scss'
import Logo from '../../assets/LogoPV.png'
import Icon from '../../components/Icon/Icon'
import { useLogoutMutation } from '../../hooks/useLogoutMutation'
import ProfileMenuEmployer from '../../components/ProfileMenu/ProfileMenuEmployer'

export default function EmployerLayout() {
  const navigate = useNavigate()
  const logoutMutation = useLogoutMutation()

  return (
    <div className={styles.main}>
      <ScrollToTop />
      <div className={styles.header}>
        <div className={styles.child1}>
          <Icon iconName={'menu'} color={'grey'} size={24} />
          <img src={Logo} alt='logo' onClick={() => navigate('/')} />
        </div>

        <div className={styles.child2}>
          {/* <Icon iconName={'search'} color={'#898989'} size={15} />
          <input type='search' defaultValue={'Search...'} /> */}
        </div>

        <div className={styles.child3}>
          <div className={styles.navBarRight}>
            {/* <Icon iconName={'questionMarkHelp'} color={'black'} /> */}
            {/* <Icon iconName={'bellNotif'} color={'black'} /> */}
            {/* <Link to='/profile'>
              <div className={styles.account}>
                <Icon iconName={'profileFillBlack'} color={'black'} size={30} />
                <Icon iconName={'arrowDown'} color={'grey'} size={12} />
              </div>
            </Link> */}
            <ProfileMenuEmployer />
          </div>
        </div>
      </div>

      <div className={styles.main2}>
        <div className={styles.child4}>
          {/* <div className={styles.it1}>
            <img src={Dashboard} alt='Dashboard' />
            <p>Dashboard</p>
          </div> */}
          <NavLink className={({ isActive }) => (isActive ? styles.selected : styles.item)} to='/emp/company-profile'>
            <Icon iconName={'companyBuilding'} color={'black'} size={15} />
            <p>Company Profile</p>
          </NavLink>

          <NavLink className={({ isActive }) => (isActive ? styles.selected : styles.item)} to='/emp/post-job'>
            <Icon iconName={'bluePaperPlane'} color={'blue'} size={15} />
            <p>Post A New Job</p>
          </NavLink>

          <NavLink className={({ isActive }) => (isActive ? styles.selected : styles.item)} to='/emp/manage-jobs'>
            <Icon iconName={'bag'} color={'black'} size={15} />
            <p>Manage Jobs</p>
          </NavLink>

          <NavLink className={({ isActive }) => (isActive ? styles.selected : styles.item)} to='/emp/all-applicants'>
            <Icon iconName={'papers'} color={'black'} size={15} />
            <p>All Applicants</p>
          </NavLink>

          {/* <div className={styles.it6}>
            <Icon iconName={'calendar'} color={'black'} size={15} />
            <p>Shortlisted Resumes</p>
          </div> */}
          {/* <div className={styles.it7}>
            <Icon iconName={'envelope'} color={'black'} size={15} />
            <p>Message</p> */}
          {/* <div className={styles.redCircle}>
              <p>8</p>
            </div> */}
          {/* </div> */}

          {/* <div className={styles.it8}>
            <Icon iconName={'lockedProfile'} color={'black'} size={15} />
            <p>Change Password</p>
          </div> */}
          <NavLink className={({ isActive }) => (isActive ? styles.selected : styles.item)} to='/emp/contact-us'>
            <Icon iconName={'envelope'} color={'black'} size={15} />
            <p>Contact Us</p>
          </NavLink>
          <div style={{ flex: 1 }}></div>

          <NavLink
            className={({ isActive }) => (isActive ? styles.selected : styles.item)}
            onClick={() => logoutMutation.mutate()}
            to='/'
          >
            <Icon iconName={'logout'} color={'black'} size={15} />
            <p>Sign Out </p>
          </NavLink>
        </div>
        <div className={styles.child5}>
          <Outlet />
        </div>
      </div>
    </div>
  )
}
