import React, { createContext, useContext, useState, ReactNode } from 'react'
import { clearTokens, getAccessToken, setAccessToken, setRefreshToken } from '../utils/auth'

interface AuthContextType {
  isAuthenticated: boolean
  authenticate: (accessToken: string, refreshToken: string) => void
  revokeAuth: () => void
}

const AuthContext = createContext<AuthContextType | undefined>(undefined)

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(!!getAccessToken())

  const authenticate = (accessToken: string, refreshToken: string) => {
    setAccessToken(accessToken)
    setRefreshToken(refreshToken)
    setIsAuthenticated(true)
  }

  const revokeAuth = () => {
    clearTokens()
    setIsAuthenticated(false)
  }

  return <AuthContext.Provider value={{ isAuthenticated, authenticate, revokeAuth }}>{children}</AuthContext.Provider>
}

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider')
  }
  return context
}
