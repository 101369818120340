import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { useState } from 'react'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import data from './assets/data.json'
import { AppContext, GlobalState } from './context/AppContext'
import { AuthProvider } from './context/AuthContext'
import CandidateApplications from './pages/CandidateApplicationsPage/CandidateApplications'
import CompanyProfilePage from './pages/CompanyProfilePage/CompanyProfilePage'
import DetailsPage from './pages/JobDetailsPage/JobDetailsPage'
import EmployerAllApplicants from './pages/EmployerAllApplicants/EmployerAllApplicants'
import EmployerLayout from './pages/EmployerLayout/EmployerLayout'
import EmployerManageJobs from './pages/EmployerManageJobs/EmployerManageJobs'
import EmployerPostNewJob from './pages/EmployerPostNewJob/EmployerPostNewJob'
import Home from './pages/HomePage/HomePage'
import Messages from './pages/MessagesPage/MessagesPage'
import NotFoundPage from './pages/NotFoundPage/NotFoundPage'
import PrivateRoute from './pages/PrivateRoute/PrivateRoute'
import Profile from './pages/ProfilePage/ProfilePage'
import ProfileRedirect from './pages/ProfilePage/ProfilePageRedirect'
import RootLayout from './pages/RootLayout/RootLayout'
import Search from './pages/SearchPage/SearchPage'
import ContactUs from './pages/ContactUs/ContactUs'
const router = createBrowserRouter([
  {
    path: '/',
    element: <RootLayout />,
    children: [
      {
        path: '*',
        element: <NotFoundPage />,
      },
      {
        path: '/',
        // element: <FakeHomePage />,
        element: <Home />,
      },
      {
        path: '/search',
        element: <Search />,
      },
      {
        path: '/messages',
        element: <PrivateRoute element={<Messages />} />,
      },
      // {
      //   path: '/about',
      //   element: <></>,
      // },
      // {
      //   path: '/explore',
      //   element: <></>,
      // },
      // {
      //   path: '/matches',
      //   element: <></>,
      // },
      {
        path: '/profile/applications',
        element: <PrivateRoute element={<CandidateApplications />} />,
      },
      {
        path: '/profile/:userId',
        element: <PrivateRoute element={<Profile />} />,
      },
      {
        path: '/profile',
        element: <PrivateRoute element={<ProfileRedirect />} />,
      },

      // {
      //   path: '/test',
      //   element: <Test />,
      // },
      {
        path: '/job/:jobId',
        element: <DetailsPage />,
      },
    ],
  },
  {
    path: '/emp',
    element: <EmployerLayout />,
    children: [
      {
        path: '/emp/post-job',
        element: <PrivateRoute element={<EmployerPostNewJob />} />,
      },
      {
        path: '/emp/company-profile',
        element: <PrivateRoute element={<CompanyProfilePage />} />,
      },
      {
        path: '/emp/all-applicants',
        element: <PrivateRoute element={<EmployerAllApplicants />} />,
      },
      {
        path: '/emp/manage-jobs/:jobId',
        element: <PrivateRoute element={<EmployerPostNewJob />} />,
      },
      {
        path: '/emp/manage-jobs',
        element: <PrivateRoute element={<EmployerManageJobs />} />,
      },
      {
        path: '/emp/contact-us',
        element: <PrivateRoute element={<ContactUs />} />,
      },
    ],
  },
])

// Create a client
const queryClient = new QueryClient()

export function App() {
  const [state, setState] = useState<GlobalState>(data)

  return (
    <QueryClientProvider client={queryClient}>
      <AppContext.Provider value={{ state, setState }}>
        <AuthProvider>
          <RouterProvider router={router} />
        </AuthProvider>
      </AppContext.Provider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}

export default App
