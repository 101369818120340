import { useQuery } from '@tanstack/react-query'
import { useAuth } from '../context/AuthContext'
import { fetchEmployerCompany } from '../api/api'
import { useCurrentUser } from './useCurrentUser'

export const useCurrentCompany = () => {
  const { isAuthenticated } = useAuth()
  const { data: user } = useCurrentUser()

  return useQuery({
    queryKey: ['currentCompany'],
    queryFn: () => {
      // Ensure companyId is defined before fetching
      if (user?.companyId === undefined) {
        throw new Error('companyId is undefined')
      }
      return fetchEmployerCompany(user.companyId)
    },
    staleTime: 1000 * 60 * 60, // 60 minutes
    enabled: isAuthenticated && !!user?.companyId,
  })
}
