import { useMutation, useQueryClient } from '@tanstack/react-query'
import { deleteExperience } from '../api/api'

export const useDeleteExperienceMutation = (userId: number) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: deleteExperience,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['user', userId] })
    },
  })
}
