import { createContext } from 'react'
import data from '../assets/data.json'
import z from 'zod'

export const LoginResponseSchema = z.object({
  person_object: z.object({
    first_name: z.string(),
    last_name: z.string(),
  }),
})
export type LoginResponse = z.infer<typeof LoginResponseSchema>

export const RegisterResponseSchema = z.object({
  person_object: z.object({
    first_name: z.string(),
    last_name: z.string(),
  }),
})
export type RegisterResponse = z.infer<typeof RegisterResponseSchema>

export interface Industry {
  id: string
  value: string
}

export interface JobType {
  id: string
  value: string
}

export interface Posted {
  label: string
  value: string
}

export interface ExperienceOption {
  label: string
  value: string
}

export interface GlobalState {
  locations: string[]
  industries: Industry[]
  jobTypes: JobType[]
  postedOptions: Posted[]
  experienceOptions: ExperienceOption[]
  bookmarkList: number[]
}

export interface AppContextInterface {
  state: GlobalState
  setState: React.Dispatch<React.SetStateAction<GlobalState>>
}
export const AppContext = createContext<AppContextInterface>({
  state: data,
  setState: () => undefined,
})
