import { useQuery } from '@tanstack/react-query'
import { searchJobs } from '../api/api'
import useSearchFilters from './useSearchFilters'

export const useSearchJobs = () => {
  const { filters } = useSearchFilters()
  return useQuery({
    queryKey: ['search', filters],
    queryFn: () => searchJobs(filters),
  })
}
