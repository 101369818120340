import React, { useContext, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { AppContext, AppContextInterface } from '../../context/AppContext'
import AutocompleteSearch from '../AutocompleteSearch/AutocompleteSearch'
import Button from '../Button/Button'
import Icon from '../Icon/Icon'
import styles from './SearchForm.module.scss'
import { useJobTitles } from '../../hooks/useJobTitles'

interface SearchFormProps {
  onSearch: (jobTitle: string, location: string) => void
  hideLocation: boolean
}

export default function SearchForm({ onSearch, hideLocation }: SearchFormProps) {
  const [searchParams] = useSearchParams()
  const [jobTitle, setJobTitle] = useState(searchParams.get('title') || '')
  const [location, setLocation] = useState(searchParams.get('location') || '')
  const context: AppContextInterface = useContext(AppContext)

  const handleSearch = (event: React.SyntheticEvent) => {
    event.preventDefault()
    onSearch(jobTitle, location)
  }

  // Query jobTitles for autocomplete
  const { isPending, isError, data: jobTitles, error } = useJobTitles()

  if (isPending) return <span>Loading...</span>
  if (isError) {
    return <span>Error: {error.message}</span>
  }

  return (
    <form className={styles.form} onSubmit={handleSearch}>
      <AutocompleteSearch
        icon={<Icon iconName={'search'} color={'grey'} size={24} />}
        placeholder='Job title, keywords...'
        data={jobTitles ?? []}
        onChange={value => setJobTitle(value)}
        value={jobTitle}
      />
      {!hideLocation && (
        <AutocompleteSearch
          icon={<Icon iconName={'location'} color={'grey'} size={24} />}
          placeholder='City or zip code'
          data={context.state.locations}
          onChange={value => setLocation(value)}
          value={location}
        />
      )}
      <Button text='Search' primary large onClick={handleSearch} />
    </form>
  )
}

SearchForm.defaultProps = { hideLocation: false }
