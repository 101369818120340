export const formatDate = (
  dateString: string | null | undefined,
  customOptions?: Intl.DateTimeFormatOptions,
): string => {
  // Configure the date format options
  const options: Intl.DateTimeFormatOptions = customOptions || {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    timeZone: 'UTC',
  }

  // Check if dateString is valid and format it
  try {
    // Check if dateString already has a time component
    const hasTime = dateString && dateString.includes('T')

    // If no time component, assume it's a date and append 'T00:00:00Z' to interpret as UTC
    const dateInput = hasTime ? `${dateString.split('T')[0]}T00:00:00Z` : `${dateString}T00:00:00Z`
    const date = new Date(dateInput)

    if (isNaN(date.getTime())) {
      // Check if date is invalid
      return 'N/A'
    }

    return new Intl.DateTimeFormat('en-US', options).format(date)
  } catch (e) {
    return 'N/A' // Return 'N/A' if any error occurs
  }
}

export function formatDateRange(startDate: string | null | undefined, endDate: string | null | undefined): string {
  // Configure the date format options
  const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', timeZone: 'UTC' }

  // Check if both dates are null or undefined
  if (!startDate && !endDate) {
    return '' // Return empty string if both dates are missing
  }

  // Generate the date range string
  return `${formatDate(startDate, options)} - ${formatDate(endDate, options)}`
}
