import React, { ReactElement, useState } from 'react'
import Icon from '../Icon/Icon'
import styles from './Dropdown.module.scss'
import { Props as IconProps } from '../Icon/Icon'
import useOutsideClick from '../../hooks/OutsideClick'
import classNames from 'classnames'

export enum Color {
  White = 'WHITE',
  Gray = 'GRAY',
}
export interface Option {
  id: string
  value: string
}
interface Props {
  onChange: (word: string) => void
  placeholder: string
  data: Option[]
  icon?: ReactElement<IconProps>
  value?: string
  bgColor: Color
  errors?: string[]
}

export default function Dropdown({ onChange, placeholder, data, icon, value, bgColor, errors }: Props) {
  const [opened, setOpened] = useState(false)
  const handleItemClick = (item: string) => {
    onChange(item)
    setOpened(false)
  }
  const ref = useOutsideClick(() => setOpened(false))
  const selectedOption = data.find(item => item.id == value)
  const inputClasses = classNames({
    [styles.input]: true,
    [styles.gray]: bgColor == Color.Gray,
    [styles.errorInput]: errors,
  })

  return (
    <div ref={ref} className={styles.container}>
      <div className={inputClasses} onClick={() => setOpened(!opened)}>
        {icon && <p className={styles.icon}>{icon}</p>}
        <div className={styles.text}>{selectedOption?.value || placeholder}</div>
        <Icon iconName={'fullArrowDown'} color={'gray'} backgroundColor={'white'} size={12} />
      </div>

      {opened && data.length > 0 && (
        <ul className={styles.list}>
          {data.map(item => (
            <li key={item.id} onClick={() => handleItemClick(item.value)}>
              {item.value}
            </li>
          ))}
        </ul>
      )}

      {errors &&
        errors.map((error, index) => (
          <p key={index} className={styles.errorMessage}>
            {error}
          </p>
        ))}
    </div>
  )
}

Dropdown.defaultProps = {
  bgColor: Color.White,
}
