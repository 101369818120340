import Icon from '../Icon/Icon'
import styles from './Timeline.module.scss'

export interface TimelineItemProps {
  id: number

  logo?: string
  title?: string
  dateRange?: string
  name?: string
  text?: string
  onEditExperience?: string
  onEditEducation?: string
}

export interface TimelineProps {
  items?: TimelineItemProps[]
  title?: string
  editable?: boolean
  onEditExperiences?: () => void
  onEditEducation?: () => void
}

function TimelineItem({ logo, title, dateRange, name, text }: TimelineItemProps) {
  return (
    <div className={styles.sectionItem}>
      <div className={styles.logo}>
        <img src={logo} alt='Logo' />
      </div>
      <div className={styles.content}>
        <div className={styles.info}>
          <div className={styles.infoTitle}>{title}</div>
          <div className={styles.date}>{dateRange}</div>
        </div>
        <div className={styles.company}>{name}</div>
        <div className={styles.text}>{text}</div>
      </div>

      <div className={styles.connector}></div>
    </div>
  )
}

export default function Timeline({ items, title, editable, onEditExperiences, onEditEducation }: TimelineProps) {
  return (
    <div className={styles.section}>
      {editable && onEditExperiences && (
        <div className={styles.roundBlueBackground} onClick={onEditExperiences}>
          <Icon iconName={'pencilEdit'} size={20} />
        </div>
      )}
      {editable && onEditEducation && (
        <div className={styles.roundBlueBackground} onClick={onEditEducation}>
          <Icon iconName={'pencilEdit'} size={20} />
        </div>
      )}
      <div className={styles.sectionTitle}>{title}</div>
      <div className={styles.sectionContainer}>
        {items?.map(item => (
          <TimelineItem key={item.id} {...item} />
        ))}
      </div>
    </div>
  )
}
