import { useCurrentUser } from '../../hooks/useCurrentUser'
import { Navigate } from 'react-router-dom'

export default function ProfileRedirect() {
  const { data: currentUser, isLoading } = useCurrentUser()
  if (isLoading) return <span>Loading...</span>

  // Redirect to the home page if not authenticated
  return <Navigate to={`/profile/${currentUser?.id}`} replace />
}
