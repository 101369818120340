import { MouseEvent, useEffect, useRef, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import userPic from '../../assets/user_candidate_Profile1.png'
import { useCurrentUser } from '../../hooks/useCurrentUser'
import { useLogoutMutation } from '../../hooks/useLogoutMutation'
import ErrorMessage from '../ErrorMessage/ErrorMessage'
import Icon from '../Icon/Icon'
import styles from './ProfileMenuCandidate.module.scss'

export default function ProfileMenu() {
  const logoutMutation = useLogoutMutation()
  const [isOpen, setIsOpen] = useState(false)
  const menuRef = useRef<HTMLDivElement>(null)

  const toggleMenu = () => {
    setIsOpen(!isOpen)
  }

  const closeMenu = () => {
    setIsOpen(false)
  }

  const handleClickOutside = (event: MouseEvent<Document>) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      closeMenu()
    }
  }
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside as any)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside as any)
    }
  }, [])

  const { isPending, isError, data: user, error } = useCurrentUser()
  if (isPending) return <span>Loading...</span>
  if (isError) return <ErrorMessage error={error} />

  return (
    <div className={styles.menu_container} ref={menuRef}>
      <div className={styles.account} onClick={toggleMenu}>
        <Icon iconName={'profileFillBlack'} color={'black'} size={30} />
        <Icon iconName={'arrowDown'} color={'grey'} size={12} />
      </div>
      {isOpen && (
        <ul className={styles.menu}>
          <div className={styles.profile}>
            <div className={styles.picture}>
              <img src={user.avatar || userPic} alt='profile picture' />
            </div>

            <div className={styles.right}>
              <p className={styles.fullName}>
                {user.firstName} {user.lastName}
              </p>
              <p className={styles.position}>{user.position}</p>
              <Link className={styles.link} to={`/profile/${user.id}`}>
                <li>View Profile</li>
              </Link>
            </div>
          </div>
          <div className={styles.parent_items}>
            <li className={styles.it1}>
              <NavLink
                className={({ isActive }) => (isActive ? styles.selected : styles.item)}
                to='/profile/applications'
              >
                <Icon iconName={'bag'} color={'black'} size={15} />
                <p>Applied Jobs</p>
              </NavLink>
              {/* <Icon iconName={'bag'} color={'black'} size={15} />
              <p>Applied Jobs</p> */}
            </li>

            {/* <li className={styles.it1}>
              <Icon iconName={'calendar'} color={'black'} size={15} />
              <p>Saved Jobs</p>
            </li> */}
            {/* <li className={styles.it1}>
              <Icon iconName={'envelope'} color={'black'} size={15} />
              <p>Message</p>
            </li> */}
            {/* <li className={styles.it1}>
              <Icon iconName={'copy'} color={'black'} size={15} />
              <p>Certificates</p>
            </li> */}
            {/* <li className={styles.it1}>
              <Icon iconName={'settings'} color={'black'} size={15} />
              <p>Setting and Privacy</p>
            </li> */}
            {/* <li className={styles.it1}>
              <Icon iconName={'world'} color={'black'} size={15} />
              <p>Language</p>
            </li> */}
            <li onClick={() => logoutMutation.mutate()} className={styles.it1}>
              <Icon iconName={'door_out'} color={'black'} size={15} />
              <p>Sign Out</p>
            </li>
          </div>
        </ul>
      )}
    </div>
  )
}
