import { useMutation, useQueryClient } from '@tanstack/react-query'
import { updateMyUser } from '../api/api'

export const useCurrentUserMutationV2 = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: updateMyUser,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['currentUser'] })
    },
  })
}
