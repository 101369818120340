import React from 'react'
import { Navigate } from 'react-router-dom'
import { useAuth } from '../../context/AuthContext'

interface PrivateRouteProps {
  element: React.ReactElement
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ element }) => {
  const { isAuthenticated } = useAuth()

  if (!isAuthenticated) {
    // Redirect to the home page if not authenticated
    return <Navigate to='/' replace />
  }

  return element
}

export default PrivateRoute
