import { useQuery } from '@tanstack/react-query'
import { getJobTitles } from '../api/api'

export const useJobTitles = () => {
  // Query jobTitles for autocomplete
  return useQuery({
    queryKey: ['jobTitles'],
    queryFn: () => getJobTitles(),
    staleTime: 1000 * 60 * 60, // 60 minutes
  })
}
