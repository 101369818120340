import classNames from "classnames"
import React from "react"
import styles from "./Badge.module.scss"

export enum BadgeType {
    Blue = "BLUE",
    Yellow = "YELLOW",
}

interface Props {
    text: string,
    type: BadgeType
}


export default function Badge({ text, type }: Props) {
    const classes = classNames({
        [styles.badge]: true,
        [styles.blue]: type == BadgeType.Blue,
        [styles.yellow]: type == BadgeType.Yellow
    })

    return (
        <div className={classes}>
            <span className={styles.text}>{text}</span>
        </div>
    )
}

