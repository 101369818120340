import React, { useRef, useState } from 'react'
import ReactCrop, { Crop, PixelCrop, centerCrop, makeAspectCrop } from 'react-image-crop'
import styles from './Uploader.module.scss'
import { canvasPreview } from './canvasPreview'
import { useDebounceEffect } from './useDebounceEffect'

import classNames from 'classnames'
import Dialog from 'rc-dialog'
import 'react-image-crop/dist/ReactCrop.css'
import Icon from '../Icon/Icon'

// This is to demonstrate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
function centerAspectCrop(mediaWidth: number, mediaHeight: number, aspect: number) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 100,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  )
}

function resizeImage(image: HTMLImageElement, maxWidth: number, maxHeight: number): string {
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  let width = image.width
  let height = image.height

  if (width > height) {
    if (width > maxWidth) {
      height *= maxWidth / width
      width = maxWidth
    }
  } else {
    if (height > maxHeight) {
      width *= maxHeight / height
      height = maxHeight
    }
  }

  canvas.width = width
  canvas.height = height

  if (ctx) {
    ctx.drawImage(image, 0, 0, width, height)
  } else {
    throw new Error('No 2D context available')
  }

  return canvas.toDataURL()
}

interface UploaderProps {
  onCropComplete: (blobUrl: string, blob: Blob, fileType: string) => void
  placeholderText?: string
  uploadCircleClass?: string
}

export default function Uploader({ onCropComplete, placeholderText, uploadCircleClass }: UploaderProps) {
  const [imgSrc, setImgSrc] = useState('')
  const previewCanvasRef = useRef<HTMLCanvasElement>(null)
  const imgRef = useRef<HTMLImageElement>(null)
  const blobUrlRef = useRef('')
  const [crop, setCrop] = useState<Crop>()
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>()
  const [scale, setScale] = useState(1)
  const [rotate] = useState(0)
  const [aspect] = useState<number | undefined>(9 / 9)
  const [, setEditingEnabled] = useState(true)
  const [, setFile] = useState<File | null>(null)

  function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined) // Makes crop preview update between images.
      const reader = new FileReader()
      const firstFile = e.target.files[0]
      reader.addEventListener('load', () => {
        const image = new Image()
        image.src = reader.result?.toString() || ''
        image.onload = () => {
          const resizedImageSrc = resizeImage(image, 150, 150)
          setImgSrc(resizedImageSrc)
          setEditingEnabled(true)
          setFile(firstFile)
        }
      })
      reader.readAsDataURL(firstFile)
    }
  }

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspect) {
      const { width, height } = e.currentTarget
      setCrop(centerAspectCrop(width, height, aspect))
    }
  }

  async function handleCropComplete(e: React.SyntheticEvent) {
    e.preventDefault()
    const image = imgRef.current
    const previewCanvas = previewCanvasRef.current
    if (!image || !previewCanvas || !completedCrop) {
      throw new Error('Crop canvas does not exist')
    }

    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height

    const offscreen = new OffscreenCanvas(completedCrop.width * scaleX, completedCrop.height * scaleY)
    const ctx = offscreen.getContext('2d') as OffscreenCanvasRenderingContext2D
    if (!ctx) {
      throw new Error('No 2d context')
    }

    ctx.drawImage(
      previewCanvas,
      0,
      0,
      previewCanvas.width,
      previewCanvas.height,
      0,
      0,
      offscreen.width,
      offscreen.height,
    )

    const blob = await offscreen.convertToBlob({
      type: 'image/png',
    })

    if (blobUrlRef.current) {
      URL.revokeObjectURL(blobUrlRef.current)
    }
    blobUrlRef.current = URL.createObjectURL(blob)

    onCropComplete(blobUrlRef.current, blob, 'image/png')
  }

  useDebounceEffect(
    async () => {
      if (completedCrop?.width && completedCrop?.height && imgRef.current && previewCanvasRef.current) {
        canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop, scale, rotate)
      }
    },
    100,
    [completedCrop, scale, rotate],
  )

  return (
    <>
      {!imgSrc && (
        <div className={styles.uploader}>
          <label htmlFor='file-upload' className={classNames(styles.uploadCircle, uploadCircleClass)}>
            {/* <div className={styles.upload}> */}
            <Icon iconName='arrowUp' />
            <p className={styles.uploadText}>{placeholderText}</p>
            {/* </div> */}
          </label>
          <input
            id='file-upload'
            type='file'
            accept='image/*'
            onChange={onSelectFile}
            style={{ display: 'none' }} // Hide the actual input but keep it in the document
          />
        </div>
      )}

      <Dialog title='Upload' onClose={() => setImgSrc('')} visible={!!imgSrc} maskAnimation='fade'>
        <div className={styles.uploadEdit}>
          {!!completedCrop && (
            <>
              <div>
                <canvas
                  ref={previewCanvasRef}
                  style={{
                    border: '1px dashed lightgrey',
                    borderRadius: '50%',
                    objectFit: 'contain',
                    width: completedCrop.width,
                    height: completedCrop.height,
                  }}
                />
              </div>

              <div className={styles.scaleRight}>
                <label htmlFor='scale-input'>Adjust Scale: </label>
                <input
                  id='scale-input'
                  type='number'
                  step='0.1'
                  value={scale}
                  disabled={!imgSrc}
                  onChange={e => setScale(Number(e.target.value))}
                />
              </div>
            </>
          )}
          {!!imgSrc && (
            <ReactCrop
              crop={crop}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              onComplete={c => setCompletedCrop(c)}
              aspect={aspect}
              minWidth={100}
              minHeight={100}
              circularCrop
            >
              <img
                ref={imgRef}
                alt='Crop me'
                src={imgSrc}
                style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                onLoad={onImageLoad}
              />
            </ReactCrop>
          )}
        </div>
        <div className={styles.buttonLogoCnt}>
          <button className={styles.buttonLogo} onClick={handleCropComplete}>
            Save Logo
          </button>
        </div>
      </Dialog>
    </>
  )
}

Uploader.defaultProps = {
  placeholderText: 'Company Logo',
}
