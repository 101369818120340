import { useNavigate } from 'react-router-dom'

import { ContactUsForm } from '../../components/ContactUsForm'

export default function ContactUs() {
  const navigate = useNavigate()

  const handleClose = () => {
    navigate(-1)
  }

  return <ContactUsForm handleClose={handleClose} />
}
