import React from 'react'
import styles from "./NavBarOption.module.scss"
import { NavLink } from 'react-router-dom';

interface Props {
    to: string,
    text?: string,
}

export default function NavBarOption({ to, text  }: Props) {
    return <NavLink 
        to={to} 
        className={({ isActive }) => isActive ? styles.navBarOptionActive : styles.navBarOption} >
            {text}
        </NavLink>
}